<template>
    <section class="section">
        <div class="item" v-for = 'item in data'>
            <div class ='title'>{{ item.name }}</div>
            <img src="../../assets/index/human.png" alt="" v-if="item.value > 25 || item.value === 0">
            <img src="../../assets/index/human-yellow.png" alt="" v-if="item.value <= 25 && item.value !== 0">
            <div class = 'percent' :class = '{ yellow : item.value <= 25 && item.value !== 0}'>{{ item.value }}%</div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
        	data: []
        }
    },
    methods: {
    	init( data ) {
    		const map = {
			    one: '01' ,
			    two: '02' ,
			    three:'03',
			    four:'04',
			    five:'05',
			    six:'06',
			    seven:'07',
			    eight:'08',
			    nine:'09',
			    ten:'10',
			    eleven:'11',
			    twelve:'12',
            }

            const arr = [] ;

    		Object.keys(map).forEach( key => {
    			arr.push({ name: map[key] , value: data[key]})
            });
    		this.data = arr
        }
    }
}
</script>

<style scoped>
    .section{
        flex:1;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: space-between;
        flex-wrap: wrap;
        padding: 5% 0 15% 0;
    }
    .item{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 33.333333%;
        margin-bottom: 2%
    }

.item .title{
    color:#fff;
    margin-right: 5px;
    font-size: 12px;
}
.percent{
    color: #31b3ee;
    font-size: 25px;
    font-weight: bolder;
    margin-left: 5px;
}
.item img{
    display: block;
    width: 15px;
    height: auto;
}
.yellow{
    color:#fff600 !important;
}
</style>
