<template>
    <section class="section">
        <div class="container">
            <div class = 'title'> 预警监督 </div>
            <div class = 'data-title'>
                <div>本月提醒 <span>{{ current[0] || 0 }}</span> 条</div>
                <div>累计提醒 <span class="yellow">{{current[1] || 0}}</span> 条</div>
            </div>
            
            <div class = 'img-section'>
                <div class = 'section-img' @click="setCurrent(0)">
                    <img src="../../assets/index/green.png" alt="">
                    <div>党员报警</div>
                </div>
                <div class = 'section-img' @click="setCurrent(1)">
                    <img src="../../assets/index/green.png" alt="">
                    <div>基础党务</div>
                </div>
                <div class = 'section-img' @click="setCurrent(2)">
                    <img src="../../assets/index/green.png" alt="">
                    <div>组织报警</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {service } from '../../service'
export default {
    data() {
        return {
        	data: [] ,
            current:[0,0]
        }
    },
    mounted() {
    	this.init()
    },
    methods:{
    	init() {
    		const thar = this ;
    		service.warn().then( r => {
    			const data = r.data ;
    			if( data.code === '1') {
				    thar.data = [
                        [data.data.memberCount , data.data.memberAllCount] ,
                        [data.data.basePartyAffairsCount , data.data.basePartyAffairsAllCount] ,
                        [data.data.partyCount , data.data.partyAllCount] ,
                    ]
				    thar.current = thar.data[0];
                }
            })
        },
	    setCurrent(index) {
    		this.current = this.data[index]
        }
    }
}
</script>

<style scoped>
.section{
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
    z-index: 999;
}
.container{
    background: url('../../assets/index/bar-bg.png') round;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto 0 15%;
}
.title{
    color: #2face6;
    font-size: 23px;
    font-weight: bolder;
}
.data-title{
    margin-left: 50px;
    color:#fff;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.data-title>div{
    white-space: pre;
}
.data-title>div:nth-of-type(2){
    margin-left: 50px;
}
.yellow{
    color:#e7dc06
}
.img-section{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex:1;
    position: relative;
    margin-left: 10%;
}
.section-img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 40px;
    width: 60px;
    font-size: 13px;
    text-align: center;
}
.section-img img {
    display: block;
    width: 100%;
    height: auto;
}
</style>