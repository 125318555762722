<template>
    <div class="container">
        <div class="header">
            <img src="../../assets/group/header.png" class = 'title'>
        </div>

        <div class="left">
            <div>
                <img style="width: 50%" class = 'title-bar' src="../../assets/index/zhibu.png" alt="">
                <line-component ref="linec"></line-component>
            </div>
            <div>
                <img class = 'title-bar' src="../../assets/index/wuge.png" alt="" style="width: 26%">
                <angle ref="angle"></angle>
            </div>
            <div>
                <img class = 'title-bar' src="../../assets/index/dabiao.png" alt="" style="width: 24%">
                <bar ref="bar"></bar>
            </div>
        </div>

        <div class="right">
            <div class = 'right-wrapper'>
                <div class="aaa">
                    <div class="sec-title">基本组织</div>
                    <div class="aaa-w">
                        <div class="aaa-a">
                            <div class="num-wrapper">
                                <div>组织设置</div>
                                <div class = 'sub-title'>支部总数</div>
                                <div>{{partyCount}}</div>

                                <div class = 'sub-num'>
                                    <div>
                                        <div class = 'sub-title'>小于3人</div>
                                        <div class = 'sub-number'>
                                            {{ lessThree }}
                                        </div>
                                    </div>
                                    <div>
                                        <div class = 'sub-title'>3-50人</div>
                                        <div class = 'sub-number'>{{ passThree }}</div>
                                    </div>
                                    <div>
                                        <div class = 'sub-title'>多于50人</div>
                                        <div class = 'sub-number'>
                                            {{ passFifty }}
                                        </div>
                                    </div>
                                    <div>
                                        <div class = 'sub-title'>未知</div>
                                        <div class = 'sub-number'>
                                            {{ other }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="aaa-b">
                            <div class = 'circle-wrapper'>
                                <div class="circle-left">
                                    <div @click="setType" class = 'circle-text circle-text-active'>网上党支部</div>
                                    <div @click="setType" class = 'circle-text '>班子职数</div>
                                    <div @click="setType" class = 'circle-text '>班组配备</div>
                                    <div @click="setType" class = 'circle-text '>党支部率属</div>
                                </div>
                                <div class="circle-right" ref="circleaaa">
                                </div>

                            </div>
                        </div>
                        <div class="aaa-c">
                            <div class="right-bar">
                                <div>按期换届</div>
                                <div class = 'right-bar-count'>
                                    <div class = 'bar-count'>
                                        <div>{{ changedTeam }}</div>
                                        <div>带换届</div>
                                    </div>
                                    <div class = 'bar-count bar-count-active'>
                                        <div>{{ changeTeam }}</div>
                                        <div>已完成</div>
                                    </div>
                                </div>
                                <bar-percent ref="haunjie"></bar-percent>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bbb">
                    <div class="sec-title">基本组织</div>
                    <div class="aaa-w">
                        <div class="asdasd">
                            <activity ref="activity"></activity>
                        </div>
                    </div>
                </div>
            </div>
            <div class = 'right-wrapper'>
                <div class="aaa">
                    <div class="sec-title">基本队伍</div>
                    <div class="aaa-w">
                        <div class="aaa-a">
                            <div class="num-wrapper">
                                <div>党员数量</div>
                                <div class = 'sub-title'>党员总数</div>
                                <div>
                                    {{ memberData.total }}
                                </div>

                                <div class = 'sub-num'>
                                    <div>
                                        <div class = 'sub-title'>正式党员</div>
                                        <div class = 'sub-number'>
                                            {{ memberData.regularNum }}
                                        </div>
                                    </div>
                                    <div>
                                        <div class = 'sub-title'>预备党员</div>
                                        <div class = 'sub-number'>{{ memberData.readyNum }}</div>
                                    </div>
                                    <div>
                                        <div class = 'sub-title'>发展对象</div>
                                        <div class = 'sub-number'>
                                            {{  memberData.developNum }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="aaa-b">
                            <div class = 'circle-wrapper'>
                                <middle ref="middle"></middle>
                            </div>
                        </div>
                        <div class="aaa-c">
                            <div class="right-bar2">
                                <div class = 'right-bar-text'>
                                    <div class = 'circle-text circle-text-active'>党费缴纳</div>
                                </div>

                                <div class="right-bar-content">
                                    <div class ='bar-text' v-for="item in feeList">
                                        <span>{{ item.name }}月</span>
                                        <span>{{ Number(item.val * 100).toFixed() > 100 ? 100 : Number(item.val * 100).toFixed()}}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bbb">
                    <div class="sec-title">基本保障</div>
                    <div class="aaa-w">
                        <div class="left-content">
                            <div class="num-wrapper">
                                <div>人员保障</div>
                                <div class = 'sub-title' style="margin-top: 1rem">党员总数</div>
                                <div>
                                    {{ memberData.total }}
                                </div>

                                <div class = 'sub-num'>
                                    <div>
                                        <div class = 'sub-title'>兼职</div>
                                        <div class = 'sub-number'>
                                            {{ memberData.developNum }}
                                        </div>
                                    </div>
                                    <div>
                                        <div class = 'sub-title'>专职</div>
                                        <div class = 'sub-number'>
                                            {{ memberData.regularNum }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-content">
                            <div class="num-wrapper" style="margin-left: 0;width:100%;">
                                <div>活动场所</div>

                                <div class = 'circle-wrapper' style="width: calc(100% - 20px);margin-top: 1rem">
                                    <div class="circle-left">
                                        <div class = 'circle-text circle-text-active'>网上党支部</div>
                                        <div class = 'circle-text '>班子职数</div>
                                    </div>
                                    <div class="circle-right" ref="circlebbb">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class = 'right-wrapper'>
                <div class="aaa" style="width: 100%;">
                    <div class="sec-title">基本制度</div>
                    <div class="aaa-ww">
                        <div class="aaa-a" style="width: 25%">
                            <div class="bottom-wrapper">
                                <div >三会一课制度</div>
                                <line-component ref = 'linecc' style="width:80%;height: 90%"></line-component>
                            </div>
                        </div>
                        <div class="aaa-b asdasd" style="width: 35%">
                           <div style="width: 50%;height: 100%">
                               <div class="bottom-wrapper">
                                   <div style="margin-left: 2rem">组织生活会</div>
                                   <div class="circle-right" ref="zuzhi" style="width: 100%;">
                                   </div>
                               </div>
                           </div>
                            <div style="width: 50%;height: 100%">
                                <middle2 ref="middle2"></middle2>
                            </div>
                        </div>
                        <div class="aaa-c asdasd" style="flex:1">
                            <div style="display:flex;width: 50%;height: 100%;flex-direction: column;justify-content: flex-start;align-items: flex-start;">
                                <div style="font-size: 0.4rem;color:#fff">民主评议党员制度</div>
                                <div style="flex:1;width: 100%;">
                                    <circlea ref="circleaac"></circlea>
                                </div>
                            </div>
                            <div style="display:flex;width: 50%;height: 100%;flex-direction: column;justify-content: flex-start;align-items: flex-start;">
                                <div style="font-size: 0.4rem;color:#fff">党务公开制度</div>
                                <div style="flex:1;width: 100%;">
                                    <div class="s-left" style="width: 100%;margin-top: 1rem">
                                        <div class = 'bar-section' v-for="item in publicMsg">
                                            <div class = 'bar-title'>{{ item.name }}</div>
                                            <div class = 'bar-content'>
                                                <div class = 'bar-wrapper'>
                                                    <div class="bar-wrapper-percent"
                                                         :style="{height: totalC ? item.nums / totalC * 100 + '%' : 0 }"
                                                    ></div>
                                                </div>
                                                <div class = 'sub-title'>剩余:{{ item.nums }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import lineComponent from '../components/line'
import angle from '../components/angle'
import bar from '../components/bar'
import barPercent from '../components/group/bar'
import activity from '../components/group/activity'
import middle from '../components/group/middle'
import middle2 from '../components/group/middle2'
import circlea from './circle'
import * as echarts from "echarts";
import {convertObj, service} from "../../service";

export default {
    components: {
        lineComponent,
        angle,
        bar,
        barPercent ,
        activity,
        middle,
        middle2,
	    circlea
    },
    data() {
        return {
	        totalC: 0,
	        basic: {
                baseOrgan:'',
                baseTeam: '',
                baseEnsure: '',
                baseActivitie: '' ,
                basicSystem: '' ,
            },
            lessThree: '' ,
            other: '' ,
            partyCount: '' ,
            passFifty: '' ,
            passThree: '' ,
            res: null,
            changeTeam: '',
            changedTeam: '',
            dzbgxls: '',
            latelyChangeTeam: '',
            feeList: [],
            memberData: {
	            total: 0,
	            regularNum: 0,
	            readyNum: 0,
	            developNum:0
            } ,
            publicMsg: [],
            MemberState: []
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {

            const that = this ;
            service.zhibu()
                .then( ({ data }) => {
                    if(data.code !== '1') return alert(data.msg) ;
                    const res = data.data
                    that.basic = convertObj(that.basic, res ) ;
                    that.setAngle( res )
                    that.setLine(res.zbgfhdbLs)
                    that.setLinec(res.threeMettingOneClass)
                    that.setBar(res.dBQKs)
                    that.jiben(res.baseParty)
                    that.initCircle()
                    that.huanjie(res.baseParty)
                    that.zhuti(res.themePartyDay)
	                that.fee(res.partyFeePayment)
                    that.res = data ;
                    that.$refs.middle.init(res)
                    that.$refs.middle2.init(res)

                    let c= 0 ;
                    res.publicMsg.forEach( item => {
                    	c += item.nums
                    })

                    this.totalC = c;
	                that.publicMsg = res.publicMsg
	                that.MemberState = res.memberState
	                that.$refs.circleaac.init(res.memberState , this.partyCount)
                    that.setZuzhi(res)
                    that.memberData = res.memberData
                })
        },
        fee( data ) {
            this.feeList = [
                {name: 1 , val: data.one || 0,},
                {name: 7 , val: data.seven || 0,},

                {name: 2, val: data.two || 0,},
                {name: 8, val: data.eight || 0,},

                {name: 3, val: data.three || 0,},
                {name: 9, val: data.nine || 0,},

                {name: 4, val: data.four || 0,},
                {name: 10, val: data.ten || 0,},

                {name: 5, val: data.four || 0,},
                {name: 11, val: data.eleven || 0},

                {name: 6, val: data.six || 0,},
                {name: 12, val: data.twelve || 0}
            ]
        },
        huanjie(res) {
            this.changeTeam = res.changeTeam
            this.changedTeam = res.changedTeam
            this.latelyChangeTeam = res.latelyChangeTeam
            this.$refs.haunjie.init(res.latelyChangeTeam)
        },
        zhuti(data){
            this.$refs.activity.init( data )
        },
        setType() {

        },
        jiben( data ) {
            this.lessThree = data.lessThree || 0
            this.other = data.other || 0
            this.partyCount = data.partyCount || 0
            this.passFifty = data.passFifty || 0
            this.passThree = data.passThree || 0
        },
        setAngle(data) {
            this.$refs.angle.init([
                data.baseOrgan ,
                data.baseEnsure,
                data.basicSystem,
                data.baseActivitie,
                data.baseTeam
            ]);
        },
        setLine( data ) {
            data = data.reverse()
            const title = data.map( item => item.year )
            const value = data.map( item => item.value )
            this.$refs.linec.init({ title ,value})
        },
	    setLinec( data ) {
		    data = data.reverse()
		    const title = data.map( item => item.name )
		    const value = data.map( item => item.nums )
		    this.$refs.linecc.init({ title ,value})
	    },
        setBar( data ) {
            this.$refs.bar.init(data)
        },
        initCircle() {
            const option = {
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['70%', '80%'],
                        avoidLabelOverlap: false,
	                    label: {
		                    show: false,
		                    position: 'center',
		                    normal: {
			                    show: true,
			                    position: 'center',
			                    color: 'rgb(47, 174, 234)',
			                    formatter: `{c|支部数}\n{b|${this.partyCount}}人`,
			                    rich: {
				                    b: {                        //name 文字样式
					                    fontSize: 30,
					                    color: 'rgb(46, 167, 224)',
					                    align:"center",
				                    },
				                    c: {                   //value 文字样式
					                    fontSize: 10,
					                    lineHeight: 20,
					                    color: 'rgb(46, 167, 224)',
					                    align:"right",
					                    padding: [15, 15, 15, 15],
				                    }
			                    }
		                    }
	                    },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            {value: this.lessThree, name: '小于3人'},
                            {value: this.passThree, name: '3-50人'},
                            {value: this.passFifty, name: '多于50人'},
                            {value: this.other, name: '未知'},
                        ]
                    }
                ]
            };
            const chart = this.$refs.circleaaa
            const circlebbb = this.$refs.circlebbb
            const e = echarts.init(chart)
            const b = echarts.init(circlebbb)
            e.setOption(option)
            b.setOption(option)
        },
        setZuzhi(res) {
            const option ={
                tooltip: {
                    trigger: 'item'
                },
                color:['#22ac38' ,'#103354'],
                legend: {
                    orient: 'vertical',
                    left: '0%',  //图例距离左的距离
                    y: 'center',  //图例上下居中
                    textStyle:{//图例文字的样式
                        color:'rgb(240, 248, 252)',
                        fontSize: 11
                    }
                },
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: ['60%', '70%'],
                        center: ["40%", "60%"], 　　　　//这个属性调整图像的位置
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderWidth:10,	//边框的宽度
                        },
                        label: {
                            show: false,
                            position: 'center',
                            normal: {
                                show: true,
                                position: 'center',
                                color: 'rgb(47, 174, 234)',
                                formatter: `{c|支部数}\n{b|${this.partyCount}}人`,
                                rich: {
                                    b: {                        //name 文字样式
                                        fontSize: 30,
                                        color: 'rgb(46, 167, 224)',
                                        align:"center",
                                    },
                                    c: {                   //value 文字样式
                                        fontSize: 10,
                                        lineHeight: 20,
                                        color: 'rgb(46, 167, 224)',
                                        align:"right",
                                        padding: [15, 15, 15, 15],
                                    }
                                }
                            }
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [res.lifeMeeting / this.partyCount ,res.notLifeMeeting  / this.partyCount ]
                    }
                ]
            };
            const chart = this.$refs.zuzhi
            const e = echarts.init(chart)
            e.setOption(option)
        }
    }
}
</script>

<style scoped lang="scss">
    @import "./style.scss";
</style>