import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import ana from "../views/ana/ana.vue";
import basic from "../views/basic/basic.vue";
import watch from "../views/watch/watch.vue";
import anaLine from "../views/ana-line/ana-line.vue";
import pic from "../views/pic/pic.vue";
import map from "../views/map.vue";
import picAna from "../views/pic-ana.vue";
import group from "../views/group";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  }, {
    path: "/ana",
    name: "ana",
    component: ana
  },{
    path: '/ana-line',
    name: 'ana-line',
    component: anaLine
  },{
    path: "/basic",
    name: "basic",
    component: basic
  },{
    path: "/watch",
    name: "watch",
    component: watch
  },{
    path: "/pic",
    name: "pic",
    component: pic
  },{
    path: "/map",
    name: "map",
    component: map
  },{
    path: "/picAna",
    name: "picAna",
    component: picAna
  },{
    path: "/group",
    name: "group",
    component: group
  }
];

const router = new VueRouter({
  routes
});

export default router;
