<template>
    <section class = 'section'>
        <div ref = 'line' class = 'line'> </div>
    </section>
</template>

<script>
import * as echarts from "echarts";

export default {
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        init( data ) {
            const option = {
                radar: {
                    splitNumber: 5,
                    name: {
                        textStyle: {
                            color: '#fff',
                            borderRadius: 3,
                            fontSize:12
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#2279a6'],
                            width:2
                        }
                    },
                    splitArea : {
                        show : true,
                        areaStyle : {
                            color: '#0f3453', // 图表背景的颜色
                        },
                    },
                    axisLine: {
                        show:false
                    },
                    indicator: [
                        { name: '基本组织', max: 100},
                        { name: '基本保障', max: 100},
                        { name: '基本制度', max: 100},
                        { name: '基本活动', max: 100},
                        { name: '基本队伍', max: 100},
                    ]
                },
                series: [{
                    type: 'radar',
                    symbol: 'circle', // 拐点的样式，还可以取值'rect','angle'等
                    symbolSize: 8, // 拐点的大小
                    areaStyle: {
                        color: '#1a7f4f',
                    },
                    itemStyle: {
                        normal: {
                            color: "#22ac38",
                            lineStyle: {
                                color: "#22ac38"
                            }
                        }
                    },
                    data: [
                        {
                            value: data,
                        }
                    ]
                }]
            };

            const chart = this.$refs.line
            const e = echarts.init(chart)
            e.setOption(option)
        }
    }
}
</script>

<style scoped>
.section{
    width: 300px;
    height: 220px;
    position: relative;
    top: 10%;
}
.section .line{
    width: 100%;
    height: 100%;
}
</style>