<template>
    <section class = 'section'>
        <div class="s-title">基本情况</div>
        <div class = 'wrapper'>
            <div class="left">
                <div class="title">书记</div>
                <div class = 'num'>
                    <span v-for = 'item in data.secretary'>{{ item }}</span>
                </div>
            </div>
            <div class="right">
                <div class="title">班子成员</div>
                <div class = 'num'>
                    <span v-for = 'item in data.teamMember'>{{ item }}</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
        	data: {}
        }
    },
    methods: {
    	init( data ) {
    		const arr = { secretary: [] , teamMember: [] }
    		let secretary = data.secretary.toString()
    		let teamMember = data.teamMember.toString()

            for ( let i = 0; i< secretary.length ; i ++ ) {
            	arr.secretary.push(secretary.charAt(i))
            }

		    for ( let i = 0; i< teamMember.length ; i ++ ) {
			    arr.teamMember.push(teamMember.charAt(i))
		    }

		    this.data = arr ;
        }
    }
}
</script>

<style scoped>
.section{
    width: 100%;
}

.wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 70px;
}
.left,
.right{
    width: 50%;
}
.left{
    margin-left: 10%;
}
.title{
    color:#fff;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}
.num{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.num > span{
    display: block;
    background: url("../../assets/index/num.png") round ;
    color: #fff;
    font-size: 35px;
    width: 40px;
    height: 55px;
    text-align: center;
    line-height: 55px;
    margin: 0 2px;
}
.s-title{
    width: 136px;
    height: 40px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAvCAMAAAB9shCAAAAAtFBMVEUAAAAup+AkmtclnNgmndkooNsil9Unn9ogldQpodwflNMqot0ektEspd8mndklmtcjmNUjmNYjmdYnntkil9UhltQglNMqodwfk9Iro90ro90spN4ekdEdkdAdkNAckNAtpd8poNsup+Aup+AtpuAtpuAup+AtpuAup+Aup+Aspd8tpuAtpuAtpuAon9sspd8up+Aup+AtpuAqot0spd8qo90jmdctpuAspN4flNMspd4up+CMz5o/AAAAO3RSTlMAgTs0LSFJJlAdVxZeCjE4Q0E/KkZMUxlaExAOYWRmaAe180Bik+WlwuG7clHHldiyEIhrzaB56q1uWqsZP98AAAGUSURBVGje1dnXcttADIXhQ1mUrGKmOIlTnRhbuexVkvf93yuaWJ7RKHScy8X3BP9gcAVgQizyKBBSJHhdnNVE1qooDLVNvVX/Dhe1r+XyV1A2uaFUNi8mW4rubkIkLUlMaRS1q5tQLVI7sSRJb+ffQqZI/tVM6mvgHim7bO6+BE/QcNG8YUCcb0iTthsWBAk8q90dExk1eJLT7YoLZ/AkzVZszE4LInXMiLKnQceMJBQDEHrJyl4BMMOSlTwFkOa3vOgEMc2ZqTPk/ZyZ1iAyCTOyQKuumHlMYdhFX3nY7g03HkbNuPFohxkzUiPav2emK5D375hRBrF/y4zLAN194IUSwOwXrHQagNgtWHEKR7r8yMiaYhzJ3TUjpsAfurxm457E891j+4mLwuCk7tdMlNTgpNH7NQujF+dX0/IzAyNJHJ1Vb4M3krq8qrtt4ErKcCGpqvF7wA6O5OR36+HwI1TlrogxRRTH7PsQDZWWeIlwvh/Gn0E5lAVp+cpv3JGvKvcQhqra+aL9r6e+EFEgcjEV/BtnRsXrNHxKqQAAAABJRU5ErkJggg==) round;
    color: #fff;
    text-align: center;
    font-size: 12px;
    line-height: 40px;
    position: absolute;
    left: 39.5%;
    top: 19px;
}
</style>