<template>
    <div class="ana-container">
        <div class="header">
            <img src="../../assets/ana/title.png" class = 'ana-title'>
        </div>
        <div class="ana-chart-container">
            <div class="ana-left">
                <div class="ana-top">
                    <div class="left-header" style="text-align: left;text-indent: 18%">
                        基本情况
                    </div>

                    <div class="basic-top">
                        <div class="sub-title">党员总数</div>
                        <div class="count-all">{{ partyMemberNum }}</div>
                    </div>
                    <div class="basic-bottom">
                        <div class="b-left">
                            <div>
                                <div class="content-wrapper">
                                    <div class="sub-title">党委</div>
                                    <div class="sub-count">
                                        {{ partyCommitteeNum }}
                                    </div>
                                </div>
                                <div class="content-wrapper">
                                    <div class="sub-title">党总支</div>
                                    <div class="sub-count">
                                        {{ generalPartyBranchNum }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="content-wrapper">
                                    <div class="sub-title">工委</div>
                                    <div class="sub-count">
                                        {{ workingCommitteeNum }}
                                    </div>
                                </div>
                                <div  class="content-wrapper">
                                    <div class="sub-title">党支部</div>
                                    <div class="sub-count">
                                        {{ partyBranchNum }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="b-right">
                            <div class="img-wrapper">
                                <div>
                                    {{ weakLaxNum }}
                                </div>
                                <div>软弱涣散</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ana-bottom">
                    <div class="left-header" style="text-align: left;text-indent: 16%">
                        支部激活情况
                    </div>
                    <div class="zhibujihuo">
                        <lineChart ref = 'lineChart'></lineChart>
                    </div>
                </div>
            </div>
            <div class = 'ana-middle'>
                <middle-top></middle-top>
            </div>
            <div class="ana-right">
                <div class="ana-top">
                    <div class="right-header" style="text-align: left;text-indent: 69%">
                        党组织分类
                    </div>

                    <div class = 'zuzhifelei'>
                        <div class = 'bar' v-for = 'item  in list'>
                            <div class="bar-title">{{ item.name }}</div>
                            <div class="bar-wrapper">
<!--                                :style="{width: item.num / total * 100 + '%'}"-->
                                <div class="percent" :style="{width:  100 + '%'}" ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ana-bottom">
                    <div class="right-header" style="text-align: left;text-indent: 63%">
                        软弱涣散党组织分析
                    </div>
                    <div class="ruannuo">
                        <div class="r-left">
                            <div class="img-wrapper">
                                <div>
                                    {{ weakLaxNum }}
                                </div>
                                <div>软弱涣散</div>
                            </div>
                        </div>
                        <div class="r-right">
                           <div class="text-num">
                               <div class="item-text-wrapper" style="color:#faee00">
                                   <div >需集中整顿</div>
                                   <div >
                                       {{ isFocusManageNum }}
                                   </div>
                               </div>
                               <div class="item-text-wrapper" style="color:#22ac38">
                                   <div>已整顿</div>
                                   <div>
                                       {{ isManageedNum }}
                                   </div>
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {service, convertObj } from '../../service'
import lineChart from './component/line'
import middleTop from '../home/component/middle-top'
export default {
	components : { middleTop, lineChart } ,
	data(){
		return {
			partyMemberNum: 0 ,
			partyCommitteeNum: 0,
			workingCommitteeNum: 0,
			generalPartyBranchNum: 0,
			partyBranchNum: 0,
			weakLaxNum: 0 ,
			isFocusManageNum: 0 ,
			isManageedNum: 0,
			list: [],
			total: 0
		}
	},
	mounted() {
		this.init();
	},
	methods:{
		init() {
			const that = this ;
			service.ana()
				.then( ({ data } ) => {
					if(data.code !== '1') return alert(data.msg) ;
					const res = data.data
					that.partyMemberNum = res.partyMemberNum
					that.partyCommitteeNum = res.partyCommitteeNum
					that.workingCommitteeNum = res.workingCommitteeNum
					that.generalPartyBranchNum = res.generalPartyBranchNum
					that.partyBranchNum = res.partyBranchNum
					that.weakLaxNum = res.weakLaxNum
					that.isFocusManageNum = res.isFocusManageNum
					that.isManageedNum = res.isManageedNum
					that.lineChart(res.partyBranchCreateDateVMs)

					that.list = res.partyTypeVMs
					let total = 0 ;
					that.list.forEach( item => total += item.nums / 1  )
					that.total = total
				})
		},
		lineChart( data  ) {
			data = data.reverse()
			const title = data.map( item => item.name )
			const value = data.map( item => item.nums )
			this.$refs.lineChart.init( { title , value} )
		}
	}
}
</script>
<style scoped lang="scss">
@import './style.scss' ;
</style>