<template>
  <div class="container">
    <div class="header">
      <img src="../../assets/basic/title.png" style="width: 10%" class = 'title'>
    </div>
    <div class="chart-container">
      <div class="top">
        <div class = 'f'>
          <div class="sub-title" style="color:rgb(46, 167, 224)">应开展次数</div>
          <div class="count" style="color:#72d6f8">
            {{ totalDevelopNum }}
          </div>
        </div>
        <div class = 's'>
          <div class="sub-title" style="color:#2f9d57">已开展次数</div>
          <div class="count" style="color:#05ed26">
            {{ totalFinishNum }}
          </div>
        </div>
        <div class ='t'>
          <div class="sub-title" style="color:#e4b547">未开展次数</div>
          <div class="count" style="color:#f9ef01">
            {{ totalNotFinishNum }}
          </div>
        </div>
        <div class ='ft'>
          <div class="sub-title" style="color:#0b9c99">参与党员人次</div>
          <div class="count" style="color:#02dfce">
            {{ totalMemberNum }}
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="b-title">下级各党组织开展情况统计</div>
        <div class="row row-title">
          <div>党组织名称</div>
          <div>应开展次数</div>
          <div>已开展次数</div>
          <div>未开展次数</div>
          <div>参与党员人次</div>
        </div>
        <div class = 'table-list'  ref="tableList">
           <div class="table-container">
              <div class="row" v-for="item in list">
                <div>
                  {{ item.partyBranch }}
                </div>
                <div>
                  {{ item.developNum }}
                </div>
                <div>
                  {{ item.finishNum }}
                </div>
                <div>
                  {{ item.notFinishNum }}
                </div>
                <div>
                  {{ item.memberNum }}
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lineChart from '../components/line' ;
import angle from '../components/angle' ;
import bar from '../components/bar'
import barAge from '../components/bar-age'
import barHuman from '../components/bar-human'
import textNum from '../components/text-num'

import percentBar from '../components/percent-bar'
import sectionPercent from '../components/section-percent'
import middleBottom from '../components/middle-bottom'
import middleTop from '../components/middle-top'
import circleChart from '../components/circle'
import * as echarts from "echarts";

import { service } from '../../service'
export default {
  components: { lineChart, angle, bar,
    percentBar, sectionPercent, barAge, barHuman ,textNum ,
    middleBottom, middleTop, circleChart
  },
  name: 'Home',
  data() {
    return {
      totalDevelopNum: 0 ,
      totalFinishNum: 0 ,
      totalNotFinishNum: 0 ,
      totalMemberNum: 0,
      list: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const that = this ;
      service.basic()
      .then( ({ data }) => {
        if(data.code !== '1') return alert(data.msg) ;
        const res = data.data
        that.totalDevelopNum = res.totalDevelopNum
        that.totalFinishNum = res.totalFinishNum
        that.totalNotFinishNum = res.totalNotFinishNum
        that.totalMemberNum = res.totalMemberNum
        that.list = res.basicPartyAffairss
        that.setScroll()
      })
    },
    setScroll() {
      const el = this.$refs.tableList ;
      this.timer = setInterval(() => {
        if(el.scrollTop <= 0 || el.scrollTop < el.offsetHeight) {
          el.scrollTop += 1
        } else if( el.scrollTop >= el.offsetHeight ){
          el.scrollTop -= 1
        }
      } , 80)
      el.onmouseover = _ => {
        clearInterval(this.timer)
      }

      el.onmouseout = _ => {
        this.setScroll()
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: url('../../assets/bg.png') round;
}
.title{
  margin: 0 auto;
  display: block;
  position: relative;
  top:20px;
}
.chart-container{
  position: absolute;
  top:80px;
  left: 0;
  height: calc(100% - 80px);
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.top{
  width: calc(95% - 20%);
  padding: 0 10%;
  margin: 0 auto;
  height: 260px;
  background: url('../../assets/basic/top.png') round;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.f,
.s,
.t,
.ft{
  width: 25%;
  box-sizing: border-box;
  text-align: center;
  padding-top: 2%;
}
.f{
  padding-right: 3%;
}

.ft{
  padding-left: 3%;
}
.sub-title{
  font-size: 20px;
  font-weight: bold;
}
.count{
  font-size: 60px;
  font-weight: bold;
}
.bottom{
  width: 100%;
  flex:1;
  margin-top: 100px;
  background: url('../../assets/basic/bottom.png') round ;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  position: relative;
}
.b-title{
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color:#fff;
}
.table-list{
  width: 80%;
  height: 80%;
  position: relative;
  overflow: scroll;
  margin-top: 50px;
}

.table-list::-webkit-scrollbar {/*滚动条整体样式*/
  display: none ;

}

.table-list::-webkit-scrollbar-thumb {/*滚动条里面小方块*/

  display: none ;

}

.table-list::-webkit-scrollbar-track {/*滚动条里面轨道*/
  display: none ;
}
.row{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.row-title{
  padding-top: 1%;
  height: 40px;
  position: absolute;
  width: 80%;
  top: 7%;
}

.row>div{
  font-size: 18px;
  font-weight: bold;
  line-height: 40px;
}
.row>div:nth-of-type(1){
  width: 45%;
  color:rgb(46, 167, 224);
  padding-left: 5%;
}
.row>div:nth-of-type(2),
.row>div:nth-of-type(3),
.row>div:nth-of-type(4),
.row>div:nth-of-type(5){
  width: 12.5%;
}
.row>div:nth-of-type(2){
  color:#72d6f8
}
.row>div:nth-of-type(3){
  color:#05ed26
}
.row>div:nth-of-type(4){
  color:#f9ef01
}
.row>div:nth-of-type(5){
  color:#02dfce
}
.table-container{
  width: 100%;
}

.table-container .row>div{
  line-height: 60px !important;
}
.table-container .row>div:nth-of-type(1){
  color:#fff !important;
}

.table-container .row:nth-child(even){
  background: #093d64;
}
</style>
