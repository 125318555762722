<template>
    <section class = 'p-wrapper'>
        <div class="title">主题党日</div>
        <div class = 'container-percent'>
            <div class="p-left">
                <div class="wrapper">
                    <div class = 'b-wrapper' v-for="item in list">
                        <div class = 'bar-section'>
                            <div class = 'bar-title'>{{ item.name }}月</div>
                            <div class = 'bar-wrapper'>
<!--                                {height: total ? 0 : item.count / total * 100 + '%'}-->
                                <div class="bar-wrapper-percent" style="height: 100%"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import bar from './bar-actrive'
export default {
    components: { bar } ,
    data() {
        return {
            list: [] ,
            total: 0 ,
        }
    },
    methods:{
        init(data){
            this.list = [
                {name: 1 , val: data.one || 0,},
                {name: 7 , val: data.seven || 0,},

                {name: 2, val: data.two || 0,},
                {name: 8, val: data.eight || 0,},

                {name: 3, val: data.three || 0,},
                {name: 9, val: data.nine || 0,},

                {name: 4, val: data.four || 0,},
                {name: 10, val: data.ten || 0,},

                {name: 5, val: data.four || 0,},
                {name: 11, val: data.eleven || 0},

                {name: 6, val: data.six || 0,},
                {name: 12, val: data.twelve || 0}
            ]

            let  t= 0 ;
            this.list.forEach( item => t += item.val )
            this.total = t ;
        }
    }
}
</script>

<style scoped>
.wrapper{
    width: 100%;
    margin-top:10px;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.p-wrapper{
    width: 90%;
    height: 100%;
    box-sizing: border-box;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.container-percent{
    width: 100%;
    flex:1 ;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.p-left{
    width: 100%;
    height: 100%;
}
.title{
    color:#fff;
}

.b-wrapper{
    width: 40%;
}
.bar-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.bar-title{
    margin-right: 10px;
    color:rgb(46, 167, 224);
    font-size: 10px;
}
.bar-wrapper{
    width: calc(100% - 10px);
    height: 5px;
    flex:1;
    margin-right: 10px;
    background: rgb(0, 55, 86);
}
.bar-wrapper-percent{
    width: 100%;
    background: -webkit-gradient(linear,66% 35%, 0% 100%, from(#2DA9DF), to(#01BA03));
    border-radius: 10px;
}
.title{
    margin-right: 10px;
    font-size: 0.4rem;
}
</style>
