<template>
  <div id="app">
    <router-view />
    <div class="left-sider" @click="expand=true"></div>
    <div class="slider-menu" :style="{left: expand  ? 0 : -160 + 'px'}">
      <div @click="nav('/')" class="menu-item" :class = '{"active-item": name === "/"}'>首页</div>
      <div @click="nav('group')" class="menu-item" :class = '{"active-item": name === "group"}'>支持部范化</div>
      <div @click="nav('ana')" class="menu-item" :class = '{"active-item": name === "ana"}'>组织体系综合分析</div>
      <div @click="nav('ana-line')" class="menu-item" :class = '{"active-item": name === "ana-line"}'>党组队伍分析</div>
      <div @click="nav('basic')" class="menu-item" :class = '{"active-item": name === "basic"}'>基础党务分析</div>
      <div @click="nav('watch')" class="menu-item" :class = '{"active-item": name === "watch"}'>预警监督分析</div>
      <div @click="nav('pic')" class="menu-item" :class = '{"active-item": name === "pic"}'>党支部画像分析</div>
      <div @click="nav('picAna')" class="menu-item" :class = '{"active-item": name === "picAna"}'>党员画像分析</div>
      <div class="close-btn" @click="expand=false">关闭</div>
    </div>
  </div>
</template>

<script>
export default  {
  data() {
    return {
      name: 'index',
      expand: false
    }
  },
  methods: {
    nav( name ) {
      this.$router.push( name ) ;
      this.expand = false ;
      this.name = name ;
    }
  }
}
</script>
<style lang="scss">
.active-item{
  background: #1283c5;
}
.menu-item{
  text-align: center;
  padding: 10px 0;
  color:#fff;
  border-bottom: 1px solid rgba(213, 197, 197, 0.17);
  transition: all .2s ease;
  cursor: pointer;
  font-size: 0.8rem;
  &:hover {
    background: #1283c5;
  }
}
.close-btn{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  text-align: center;
  border-top: 1px solid #ccc;
  color:#fff;
  line-height: 40px;
  font-size: 0.8rem;
  cursor: pointer;
}
.slider-menu{
  width: 160px;
  height: calc(100%  - 50px);
  padding-bottom: 50px;
  position: absolute;
  background: rgba(38, 107, 147, 0.95);
  left: 0;
  top:0;
  z-index: 99999;
  transition: all .2s ease;
}
.left-sider{
  width: 10px;
  height: 40px;
  border-radius: 0 5px 5px 0;
  background: rgba(38, 107, 147, 0.68);
  position: absolute;
  left: 0;
  top: calc(50% - 20px);
  cursor: pointer;
  z-index: 99998;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
