<template>
    <section class="section">
        <div class = 'b-wrapper'>
            <div class = 'bar-section'>
                <div class = 'bar-title'>01</div>
                <div class = 'bar-wrapper'>
                    <div class="bar-wrapper-percent"></div>
                </div>
            </div>
        </div>

        <div class = 'b-wrapper'>
            <div class = 'bar-section'>
                <div class = 'bar-title'>01</div>
                <div class = 'bar-wrapper'>
                    <div class="bar-wrapper-percent"></div>
                </div>
            </div>
        </div>

        <div class = 'b-wrapper'>
            <div class = 'bar-section'>
                <div class = 'bar-title'>01</div>
                <div class = 'bar-wrapper'>
                    <div class="bar-wrapper-percent"></div>
                </div>
            </div>
        </div>

        <div class = 'b-wrapper'>
            <div class = 'bar-section'>
                <div class = 'bar-title'>01</div>
                <div class = 'bar-wrapper'>
                    <div class="bar-wrapper-percent"></div>
                </div>
            </div>
        </div>

        <div class = 'b-wrapper'>
            <div class = 'bar-section'>
                <div class = 'bar-title'>01</div>
                <div class = 'bar-wrapper'>
                    <div class="bar-wrapper-percent"></div>
                </div>
            </div>
        </div>

        <div class = 'b-wrapper'>
            <div class = 'bar-section'>
                <div class = 'bar-title'>01</div>
                <div class = 'bar-wrapper'>
                    <div class="bar-wrapper-percent"></div>
                </div>
            </div>
        </div>


        <div class = 'b-wrapper'>
            <div class = 'bar-section'>
                <div class = 'bar-title'>01</div>
                <div class = 'bar-wrapper'>
                    <div class="bar-wrapper-percent"></div>
                </div>
            </div>
        </div>

        <div class = 'b-wrapper'>
            <div class = 'bar-section'>
                <div class = 'bar-title'>01</div>
                <div class = 'bar-wrapper'>
                    <div class="bar-wrapper-percent"></div>
                </div>
            </div>
        </div>

        <div class = 'b-wrapper'>
            <div class = 'bar-section'>
                <div class = 'bar-title'>01</div>
                <div class = 'bar-wrapper'>
                    <div class="bar-wrapper-percent"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style scoped>
.section{
    width: 100%;
    margin-top:10px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.b-wrapper{
    width: 40%;
    height: 16.6666%;
}
.bar-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.bar-title{
    margin-right: 10px;
    color:rgb(46, 167, 224);
    font-size: 10px;
}
.bar-wrapper{
    width: calc(100% - 10px);
    height: 5px;
    flex:1;
    margin-right: 10px;
    background: rgb(0, 55, 86);
}
.bar-wrapper-percent{
    width: 80%;
    height: 100%;
    background: -webkit-gradient(linear,66% 35%, 0% 100%, from(#2DA9DF), to(#01BA03));
    border-radius: 10px;
}
.title{
    font-size: 12px;
    margin-right: 10px;
}
</style>