<template>
    <section class = 'section-container'>
        <div class="s-left" style="width: 100%;">
            <div class="title">教育培训方式内容</div>
            <div class = 'bar-section'>
                <div class = 'bar-title'>每季开展讲党课</div>
                <div class = 'bar-content'>
                    <div class = 'bar-wrapper'>
                        <div class="bar-wrapper-percent"
                             :style="{height: totalC ? partyClass / totalC * 100 + '%' : 0 }"
                        ></div>
                    </div>
                    <div class = 'sub-title'>剩余:{{ partyClass }}</div>
                </div>
            </div>
            <div class = 'bar-section'>
                <div class = 'bar-title'>课程教育学习年度月度计划</div>
                <div class = 'bar-content'>
                    <div class = 'bar-wrapper'>
                        <div class="bar-wrapper-percent"
                             :style="{height: totalC ? plane / totalC * 100 + '%' : 0 }"
                        ></div>
                    </div>
                    <div class = 'sub-title'>剩余:{{ plane }}</div>
                </div>
            </div>

            <div class = 'bar-section'>
                <div class = 'bar-title'>参加万名党员进党校计划</div>
                <div class = 'bar-content'>
                    <div class = 'bar-wrapper'>
                        <div class="bar-wrapper-percent"
                             :style="{height: totalC ? partySchool / totalC * 100 + '%' : 0 }"
                        ></div>
                    </div>
                    <div class = 'sub-title'>剩余:{{ partySchool }}</div>
                </div>
            </div>

            <div class = 'bar-section'>
                <div class = 'bar-title'>开展其他类型培训</div>
                <div class = 'bar-content'>
                    <div class = 'bar-wrapper'>
                        <div class="bar-wrapper-percent"
                             :style="{height: totalC ? other / totalC * 100 + '%' : 0 }"
                        ></div>
                    </div>
                    <div class = 'sub-title'>剩余:{{other}}</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import * as echarts from "echarts";

export default {
    data() {
        return {
            other: 0,
            partyClass: 0,
            partySchool: 0,
            plane: 0,
	        totalC: 0 ,
        }
    },
    methods: {
        init( data ){
            this.other = data.other
            this.partyClass = data.partyClass
            this.partySchool = data.partySchool
            this.plane = data.plane
	        this.totalC = this.other+ this.partyClass+ this.partySchool+ this.plane
        },
        setTime(data) {
            const option = {
                xAxis: {
                    type: 'category',
                    data: ['党员', '支部书记', '班子成员'],
                    axisLabel: {
                        textStyle: {
                            color: '#fff',
                            fontSize:'5',
                        }
                    },
                    splitLine:{
                        show:false
                    },
                },
                yAxis: {
                    splitLine:{
                        show:false
                    },
                    textStyle: {
                        color: '#fff',
                        fontSize:'8',
                    },
                    type: 'value'
                },
                series: [{
                    data: [data.all, data.lead, data.team],
                    type: 'bar',
                    showBackground: true,
                    barWidth: '30%',
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgb(3, 178, 5)'
                        }, {
                            offset: 1,
                            color: 'rgb(46, 167, 224)'
                        }]),
                        barBorderRadius:[10, 10, 10, 10],
                    },
                    barBorderRadius:[10, 10, 10, 10],
                    backgroundStyle: {
                        color: 'rgb(23, 42, 134)',
                    }
                }]
            };

            const chart = this.$refs.time
            const e = echarts.init(chart)
            e.setOption(option)
        }
    }
}
</script>

<style scoped>
.section-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.s-left,
.s-right{
    width: 50%;
    height: 100%;
}
.s-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.s-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
}
.title{
    color:#fff;
    margin-bottom: 10px;
    font-size: .4rem;
}
.bar-section{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.bar-title{
    margin-right: 20px;
    color:rgb(46, 167, 224);
    font-size: 10px;
}
.bar-wrapper{
    width: calc(100% - 10px);
    height: 5px;
    flex:1;
    margin-right: 10px;
    background: #172a88;
    border-radius: 20px;
}
.bar-wrapper-percent{
    width: 70%;
    background: -webkit-gradient(linear,66% 35%, 0% 100%, from(#2DA9DF), to(#01BA03));
    border-radius: 20px;
}
.sub-title{
    font-size: 12px;
    color:#8fc31f
}
.bar-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.bar-wrapper-content{
    flex:1;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.bar-num{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.bar-num > span {
    font-size: 12px;
    color:#2ea7e0;
    margin-bottom: 3px;
}

.bar-section-num{
    flex:1;
    height: 100%;
    padding-left: 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color:#fff;
}

.num-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-right: 17px;
}
.num-title{
    font-size: 12px;
}
.percent{
    width: 14px;
    height: calc(100% - 5px);
    background: #172a88;
    margin-bottom: 5px;
    border-radius: 10px;
    position: relative;
}
.current-percent{
    transition: all .2s ease-in;
    width: 100%;
    height: 60%;
    background: -webkit-gradient(linear,66% 35%, 0% 100%, from(#01BA03), to(#2DA9DF));
    border-radius: 10px;
    position: absolute;
    bottom: 0;

}
</style>
