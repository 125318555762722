<template>
    <div class="container">
        <div class="header">
            <img src="../assets/pic/title.png" class = 'title'>
        </div>
        <div class="expand-wrapper" :class="{'expand' : expand }">
            <div class="list-item-wrapper wrapper-bottom">
                <div v-for="item in list" class="s-item" @click="getData(item.id);expand=false">
                    {{ item.name}}
                </div>
            </div>
            <div class="close" @click="expand =false">关闭</div>
        </div>
        <div class="wrapper">
            <div class="left">
                <div class="slider" @click="expand = true ">展开</div>
                <div class = 'left-header'>
                    基本情况
                </div>
                <div class="l-top">
                    <div class="head">
                        <img :src="'http://kyzzb.bizzar.cn/head/' +head" alt="">
                    </div>
                    <div style="font-size: 25px;color:#fff;text-align: center;margin-top: 10px">
                        {{ name }}
                    </div>

                    <div class = 'name'>
                        <span>{{ nation }}</span>
                        <span>{{ sex }}</span>
                        <span>{{age}}岁</span>
                        <span>{{ education }}</span>
                    </div>

                    <div class = 'name'>
                        <span>入党时间:{{ joinDate }}</span>
                        <span style="border-right: none;display: flex;flex-direction: row;justify-content: center;align-items: center">
                            <img src="../assets/pic/ttel.png" alt="">
                            <span>
                                {{ mobile }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">党员画像</div>
                    <div id="tagbox" style="display: none">
                        <a href="#" v-for="item in huaxiang" :style="{color: getColor(item)}">
                            {{ item }}
                        </a>
                    </div>
                </div>
                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">三会一课</div>
                    <div class="sh-top">
                        <div class="shtitle">党课</div>
                        <div class="iconasdasd">
                            <div class = 'iconasdasdaaa' v-for="(item,index) in sanhui" >
                                <span>{{ index + 1 }}</span>
                                <img v-if="item" src="../assets/pic-ana/check.png" alt="">
                                <img v-if="!item" src="../assets/pic-ana/info.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="sh-bottom">
                        <div class="shtitle">党员大会</div>
                        <div class="iconasdasd">
                            <div class = 'iconasdasdaaa' v-for="(item,index) in sanhuia" >
                                <span>{{ index + 1 }}</span>
                                <img v-if="item" src="../assets/pic-ana/check.png" alt="">
                                <img v-if="!item" src="../assets/pic-ana/info.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">主题党日</div>
                    <div class = 'chart sanhuiyike-wrapper'>
                        <div class = 'sanhuiyike' v-for="(item,index) in zhuti" >
                            <span>{{ index + 1 }}</span>
                            <img v-if="item" src="../assets/pic-ana/check.png" alt="">
                            <img v-if="!item" src="../assets/pic-ana/info.png" alt="">
                        </div>
                    </div>
                </div>


                <div class="item-wrapper">
                     <div class="item-wrapper-title" style="margin-bottom: 10px">基础党务</div>
                    <div class = 'chart' style="display: flex;flex-direction: row;justify-content: center;align-items: center">
                       <angle ref="angle"></angle>
                    </div>
                </div>
                <div class="item-wrapper">
                     <div class="item-wrapper-title" style="margin-bottom: 10px">学习情况</div>
                    <div id="tagbox2" style="display: none">
                        <a href="#" v-for="item in xueli" :style="{color: getColor(item)}">
                            {{ item }}
                        </a>

                    </div>
                </div>
                <div class="item-wrapper">
                     <div class="item-wrapper-title" style="margin-bottom: 10px">党费缴费</div>
                    <div class = 'chart sanhuiyike-wrapper'>
                        <div class = 'sanhuiyike' v-for="(item,index) in dangfei" >
                            <span>{{ index + 1 }}</span>
                            <img v-if="item" src="../assets/pic-ana/check.png" alt="">
                            <img v-if="!item" src="../assets/pic-ana/info.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import lineChart from './components/line' ;
import angle from './components/picAna/angle' ;
import bar from './components/bar'
import barAge from './components/bar-age'
import barHuman from './components/bar-human'
import textNum from './components/text-num'

import percentBar from './components/percent-bar'
import sectionPercent from './components/section-percent'
import middleBottom from './components/middle-bottom'
import middleTop from './components/middle-top'
import circleChart from './components/pic/circle'
import * as echarts from "echarts";
import { load } from "./script";
import { load as loading } from "./b";
import {service, convertObj } from '../service'

export default {
    components: { lineChart, angle, bar,
        percentBar, sectionPercent, barAge, barHuman ,textNum ,
        middleBottom, middleTop, circleChart
    },
    data() {
        return {
            name: '' ,
            nation: '' ,
            sex: '' ,
            age: '' ,
            education: '' ,
            joinDate: '' ,
            sanhui: [],
	        sanhuia: [],
            zhuti: [] ,
            mobile: '',
            dangfei: [],
            expand: false,
            list: [],
	        huaxiang: [] ,
	        xueli: [],
            index:1,
            timer: null,
	        head: ''
        }
    },
    name: 'Home',
    destroyed() {
    	clearInterval(this.timer)
    },
	mounted() {
	    service.mlist().then( r => {
		    this.list = r.data.data.list ;
		    this.getData(1)
	    })
        //
        // this.timer = setInterval( () => {
        // 	this.getData(this.list[this.index].id)
        // } , 10000 )
    },
    methods: {
	    getColor( item) {
	    	if( item === '正式党员') return '#22ac38'
        },
    	getData(id) {
		    service.member({id})
			    .then( res => {
				    const data = res.data.data ;
				    this.name = data.name ;
				    this.nation = data.nation ;
				    this.sex = data.sex ;
				    this.age = data.age ;
				    this.education = data.education ;
				    this.joinDate = data.joinDate
				    this.mobile = data.mobile

                    this.head = data.head ;
				    this.sanHuiYike(data.partyMemberMetting)
				    this.zhutif(data.themePartyDay)
				    this.angle(data.memberBasePartyAffairsVM)
				    this.dangFefi( data.partyFeePayment )
				    this.huixiang(data.draw)
				    this.xuexi(data.studyDetails)


			    })
        },
        huixiang(words) {
            const arr = words ? words.split(',') : [] ;
            this.huaxiang = arr ;
            setTimeout(()=> {
	            load('tagbox')
            } , 100)
        },
        xuexi(words) {
	        this.xueli = ['积极参加学习','按时完成组织任务','开展学习情况优秀'] ;
	        setTimeout(()=> {
		        loading('tagbox2')
	        } , 100)
        },
        dangFefi(data ) {
            this.dangfei = [
                data.one,
                data.two,
                data.three,
                data.four,
                data.five,
                data.six,
                data.seven,
                data.eight,
                data.nine,
                data.ten,
                data.eleven,
                data.twelve
            ]
        },
        angle(data) {
            const d = [
                data.partyClass,
                data.partyMemberMetting,
                data.themePartyDay,
                data.branchCommitteeMetting,
                data.partyFeePayment
            ]
            this.$refs.angle.init( d ) ;
        },
        zhutif(data) {
            this.zhuti = [
                data.one,
                data.two,
                data.three,
                data.four,
                data.five,
                data.six,
                data.seven,
                data.eight,
                data.nine,
                data.ten,
                data.eleven,
                data.twelve
            ]
        },
        sanHuiYike(data) {
            this.sanhui = [
                data.one,
                data.two,
                data.three,
                data.four,
                data.five,
                data.six,
                data.seven,
                data.eight,
                data.nine,
                data.ten,
                data.eleven,
                data.twelve
            ]
	        this.sanhuia = [
		        true,
		        true,
		        true,
		        true,
		        true,
		        true,
		        true,
		        true,
		        true,
		        true,
		        true,
		        true,
	        ]
        },
        setAgeChart() {
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                legend: {
                    data: ['利润', '支出']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'value'
                    }
                ],
                yAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            show: false
                        },
                        data: ['16-25', '25-35', '35-45', '45-55', '55-60', '60以上']
                    }
                ],
                series: [
                    {
                        name: '利润',
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'inside'
                        },
                        data: [200, 170, 240, 244, 200, 220]
                    },
                    {
                        name: '支出',
                        type: 'bar',
                        stack: '总量',
                        label: {
                            show: true,
                            position: 'left'
                        },
                        data: [-120, -132, -101, -134, -190, -230,]
                    }
                ]
            };
            const chart = this.$refs.chart
            const e = echarts.init(chart)
            e.setOption(option)
        }
    }
}
</script>

<style scoped>
.container {
    width: 100%;
    height: 100%;
    background: url('../assets/bg.png') round;
}
.title{
    margin: 0 auto;
    display: block;
    position: relative;
    top:20px;
}
.wrapper{
    width: 90%;
    height: calc(100% - 190px);
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;
}
.left {
    height: 100%;
    width: calc(30% - 20px);
    box-sizing: border-box;
    margin-left: 20px;
    background: url("../assets/pic/wrapper.png") round;
    position: relative;
}
.left-header{
    background: url("../assets/pic-ana/header.png") round;
    height: 11%;
    position: absolute;
    top: 0px;
    width: 80%;
    left: 10%;
    text-align: center;
    font-size: 2rem ;
    color:#fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.right{
    height: 100%;
    width: calc(70% - 20px);
    margin-left: 20px;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.l-top,
.l-middle,
.l-bottom{
    width: 100%;
}
.l-top{
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.head{
    width: 150px;
    height: 150px;
}
.head>img{
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.sub{
    background: url("../assets/pic/sub.png") round ;
    width: 50%;
    height: 16%;
    color: #fff;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
}
.sub-title{
    width: 50%;
    font-size: 22px;
    word-break: break-all;
    text-align: center;
    color:#fff;
    margin: 30px auto 0;
}
.sub-num{
    margin: 30px auto 0;
    background: url("../assets/pic/num.png") round;
    width: 270px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    font-size: 1.3rem ;
    color:#fff;
}
.sub-num>span{
    color:#2694c8
}
.l-bottom{
    height: 40%;
    width: calc(80% - 2px);
    border-top: 2px solid rgb(44, 145, 198);
    border-radius: 20px 20px 0 0;
    padding: 0 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.l-middle>img{
    width: 80px;
    display: block;
    height: 80px;
}


.list-item> img {
    width: 80px;
    display: block;
    height: 80px;
    margin-right: 10px;
}

.item-wrapper{
    width: calc(33.333% - 20px);
    height: 48%;
    margin: 0 10px;
    margin-bottom: 2%;
    background: url("../assets/pic/wrapper.png") round;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.item-wrapper-title{
    background: url("../assets/pic/sub.png") round;
    width: 50%;
    height: 9%;
    color: #fff;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem ;
}
.sh-top,
.sh-bottom {
    height: 100%;
    width: 100%;
    padding: 0 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center;
    color:#fff
}
.sh-top{
    border-bottom: 1px solid #008dd1
}
.shtitle{
    width: 20px;
    color:#fff
}
.iconasdasd{
    flex: 1;
    height: 100%;
    padding: 5% 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.iconasdasdaaa{
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.iconasdasdaaa> span{
    width: 40px;
}
.iconasdasdaaa> img {
    display: block;
    width: 29%;
}
.chart{
    width: 100%;
    flex: 1;
}



.bar-wrapper-content{
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 100px auto 0 auto;
    position: relative;
}

.bar-num{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 16%;
}

.bar-num > span {
    font-size: 12px;
    color:#2ea7e0;
    margin-bottom: 8px;
}

.bar-section-num{
    flex:1;
    height: 100%;
    padding-left: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    color:#fff;
}

.num-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-right: 17px;
}
.num-title{
    font-size: 12px;
}
.percent{
    width: 14px;
    height: calc(100% - 5px);
    background: #172a88;
    margin-bottom: 5px;
    border-radius: 10px;
    position: relative;
}
.current-percent{
    transition: all .2s ease-in;
    width: 100%;
    height: 60%;
    background: -webkit-gradient(linear,66% 35%, 0% 100%, from(#01BA03), to(#2DA9DF));
    border-radius: 10px;
    position: absolute;
    bottom: 0;

}
.name{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.name>span{
    padding: 0 20px;
    margin-top: 10px;
    color:#2ea7e0;

}
.name>span:nth-of-type(1),
.name>span:nth-of-type(2),
.name>span:nth-of-type(3){
    border-right: 1px solid #2ea7e0;
}
.sanhuiyike-wrapper{
    width: 70%;
    margin: 0 auto ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.sanhuiyike{
    width: 33.33333%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.sanhuiyike span{
    color:#fff;
    margin-right: 5px;
    font-size: 15px;
}
.sanhuiyike img{
    display: block;
    width: 23%;
    height: auto;
}
.slider{
    width: 30px;
    height: 100px;
    position: absolute;
    background: #10466cad;
    line-height: 50px;
    color: #fff;
    top: 26px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}
/* 定义滚动条样式 */
.wrapper-bottom::-webkit-scrollbar {
    display: none;
}

/*定义滚动条轨道 内阴影+圆角*/
.wrapper-bottom::-webkit-scrollbar-track {
    display: none;
}

/*定义滑块 内阴影+圆角*/
.wrapper-bottom::-webkit-scrollbar-thumb {
    display: none;
}

.expand-wrapper{
    width: 200px;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 50px;
    position: absolute;
    left: -200px;
    top: 0;
    background: #1a4a70;
    z-index: 999;
    transition: all 0.2s ease;
    overflow: hidden;
}
.expand{
    left:0
}
.close{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 40px;
    text-align: center;
    color:#fff;
    border-top: 1px solid rgba(101, 101, 101, 0.34);
    cursor: pointer;
}
.s-item{
    padding: 10px 0;
    color:#fff;
    border-bottom: 1px solid rgba(101, 101, 101, 0.34);
}
.list-item-wrapper{
    width: 100%;
    height: 100%;
    overflow-y: scroll;

}

#tagbox,#tagbox2{position:relative;margin:20px auto 0px;width:100%;height:100%}
#tagbox a, #tagbox2 a{position:absolute;padding:3px 6px;font-family:Microsoft YaHei;color:#fff;TOP:0px;font-weight:bold;text-decoration:none;left:0px}
</style>
