<template>
  <div class="h-container">
    <div class="header">
      <img src="../../assets/index/title.png" class='h-title'>
    </div>
    <div class="h-chart-container">
      <div class="h-left">
        <img class="h-img-title" src="../../assets/index/title-left.png">
        <div class="h-chart-item">
          <img src="../../assets/index/zhibu.png" class = 'h-chart-title'>
          <line-chart ref="lineChart" ></line-chart>
        </div>
        <div class="h-chart-item">
          <img src="../../assets/index/wuge.png" class = 'h-chart-title' style="width: 27%;">
          <angle ref="angle"></angle>
        </div>
        <div class="h-chart-item">
          <img src="../../assets/index/dabiao.png" class = 'h-chart-title' style="width: 27%;">
          <bar ref="bar"></bar>
        </div>
      </div>

      <div class="h-middle">
        <middle-top></middle-top>
        <middle-bottom></middle-bottom>
      </div>

      <div class="h-right">
        <img class="h-img-title" src="../../assets/index/title-right.png">
        <div class="h-chart-item">
          <img src="../../assets/index/sanhui.png" class = 'h-chart-title'>
          <percent-bar ref="percentBar"></percent-bar>
        </div>
        <div class="h-chart-item">
          <img src="../../assets/index/zhuti.png" class = 'h-chart-title' style="width: 28%;">
          <section-percent ref="sectionPercent"></section-percent>
        </div>
        <div class="h-chart-item" style="margin-bottom: 0">
          <img src="../../assets/index/shenghuo.png" class = 'h-chart-title' style="width: 33%;">
          <circle-chart ref="circleChart"></circle-chart>
        </div>
      </div>

      <div class="h-bottom">
        <img src="../../assets/index/dangzu.png" class="dangzu" alt="">
        <div class="h-bottom-wrapper">
          <div class="h-bottom-wrapper-left">
            <div class = 'item-title'>发展党员情况</div>
            <bar-human ref="barHuman"></bar-human>
          </div>
          <div class="h-bottom-wrapper-middle">
            <div class = 'item-title'>基本情况</div>
            <text-num ref="textNum"></text-num>
          </div>
          <div class="h-bottom-wrapper-right">
            <div class = 'item-title'>年龄结构</div>
            <bar-age ref="barAge"></bar-age>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {service, convertObj } from '../../service'
import lineChart from './component/line'
import angle from './component/angle'
import bar from './component/bar'
import percentBar from './component/percent-bar'
import sectionPercent from './component/section-percent'
import circleChart from './component/circle'
import middleBottom from './component/middle-bottom'
import middleTop from './component/middle-top'
import barHuman from './component/bar-human'
import barAge from './component/bar-age'
import textNum from './component/text-num'
export default {
  components: {lineChart, angle, bar, percentBar,
    sectionPercent,
    circleChart ,
    middleBottom, middleTop,
    barHuman, barAge, textNum
  },
  name: "Home",
  mounted() {
    this.init();
  },
  methods: {
    init() {
      service.index()
        .then( ({ data }) => {
          if(data.code !== '1') return alert(data.msg) ;
          const res = data.data
          this.setLine(res.zbgfhdbLs)
          this.setAngle( res )
          this.setBar(res.dBQKs)
          this.percentBar( res.threeMeetingOneClass )
          this.sectionPercent( res.themePartyDay )
          this.circleChart([
            {name: '已开展支部数' , nums:res.lifeMeeting} ,
            {name: '未开展支部数' , nums:res.notLifeMeeting} ,
          ])
          this.barHuman( res.memberAdd )
          this.barAge( res.ageGroup )
          this.textNum({
            teamMember: res.teamMember ,
            secretary:res.secretary
          })
        })
    },
    barHuman( data ) {
      this.$refs.barHuman.init( data ) ;
    },
    barAge( data ) {
      this.$refs.barAge.init(data);
    },
    textNum( data ) {
      this.$refs.textNum.init( data ) ;
    },
    percentBar( data ) {
      this.$refs.percentBar.init(data);
    },
    circleChart( data ) {
      this.$refs.circleChart.init(data);
    },
    sectionPercent( data ) {
      this.$refs.sectionPercent.init(data);
    },
    setBar( data ) {
      this.$refs.bar.init(data)
    },
    setLine( data ) {
      data = data.reverse()
      const title = data.map( item => item.year )
      const value = data.map( item => item.value )
      this.$refs.lineChart.init({ title ,value})
    },
    setAngle(data) {
      this.$refs.angle.init([
        data.baseOrgan ,
        data.baseEnsure,
        data.basicSystem,
        data.baseActivitie,
        data.baseTeam
      ]);
    }
  }
};
</script>

<style scoped lang="scss">
@import './style.scss' ;
</style>