<template>
    <section class="section">
        <div class = 'bar-section' v-for = 'item in data '>
            <div class = 'bar-wrapper'>
<!--                :style="{height: item.value / total * 100 + '%'}" -->
                <div class="bar-wrapper-percent" :style="{height: width() }">
                    <div class = 'num-title' v-if="item.value > 0">{{ item.value }}</div>
                </div>
            </div>
            <div class = 'bar-title'>{{ item.name }}</div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
        	data: {},
	        total: 0
        }
    },
    methods:{
	    width( i ) {
		    return Math.random() * 100 + '%';
	    },
    	init(data) {
		    const map = {
			    one: '01' ,
			    two: '02' ,
			    three:'03',
			    four:'04',
			    five:'05',
			    six:'06',
			    seven:'07',
			    eight:'08',
			    nine:'09',
			    ten:'10',
			    eleven:'11',
			    twelve:'12',
		    }

		    const arr = [] ;
		    let total = 0 ;
		    Object.keys(map).forEach( key => {
		    	total += data[key] / 1
			    arr.push({ name: map[key] , value: data[key]})
		    });

		    this.total = total || 1;
		    this.data = arr
        }
    }
}
</script>

<style scoped>
.num-title{
    font-size: 0.5rem;
    color: #e6b70b;
    margin-top: 16px;
    transform: scale(0.5);
    position: absolute;
    top: -35px;
    left: -37px;
    width: 80px;
    text-align: center;
}
.section{
    width: 100%;
    position: relative;
    height: 82%;
    padding: 5% 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}
.bar-section{
    width: 18px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
}
.bar-title{
    color:#2faae3;
    font-size: 10px;
    font-weight: bolder;
    margin-top: 5px;
    transform: scale(0.8);
}
.bar-wrapper{
    width: 5px;
    height: 80%;
    flex:1;
    position: relative;
}
.bar-wrapper-percent{
    width:100%;
    background: -webkit-gradient(linear,66% 35%, 0% 100%, from(#2DA9DF), to(#01BA03));
    border-radius: 10px;
    position: absolute;
    bottom: 0px;
}
</style>