<template>
    <section class="section">
        <div class = 'bar-section' v-for = ' item in list'>
            <div class = 'bar-title'>
                {{ item.name }}
            </div>
            <div class = 'bar-wrapper'>
                <div class="bar-wrapper-percent" :style="'width:' + width()"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
        	list: []
        }
    },
    methods: {
    	init( data ) {
		    this.list = data;
        },
        width( i ) {
    		return Math.random() * 100 + '%';
        }
    }
}
</script>

<style scoped>
.section{
    width: 100%;
    flex:1;
    padding-top: 5%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.bar-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex:1
}
.bar-title{
    margin-right: 20px;
    color: #fff;
    font-size: 0.8rem;
    width: 9rem;
    text-align: left;
}
.bar-wrapper{
    width: 100%;
    height: 5px;
    flex:1
}
.bar-wrapper-percent{
    width: 80%;
    height: 100%;
    background: -webkit-gradient(linear,66% 35%, 0% 100%, from(#2DA9DF), to(#01BA03));
    border-radius: 10px;
}
</style>
