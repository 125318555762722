<template>
    <section class="section">
        <div class="item">
            <div class ='title'>01</div>
            <img :src="item" alt="" v-for = 'item in data.one' />
        </div>

        <div class="item">
            <div class ='title'>02</div>
            <img :src="item" alt="" v-for = 'item in data.two' />
        </div>

        <div class="item">
            <div class ='title'>03</div>
            <img :src="item" alt="" v-for = 'item in data.three' />
        </div>

        <div class="item">
            <div class ='title'>04</div>
            <img :src="item" alt="" v-for = 'item in data.four' />
        </div>

        <div class="item">
            <div class ='title'>05</div>
            <img :src="item" alt="" v-for = 'item in data.five' />
        </div>

        <div class="item">
            <div class ='title'>06</div>
            <img :src="item" alt="" v-for = 'item in data.six' />
        </div>

        <div class="item">
            <div class ='title'>07</div>
            <img :src="item" alt="" v-for = 'item in data.seven' />
        </div>

        <div class="item">
            <div class ='title'>08</div>
            <img :src="item" alt="" v-for = 'item in data.eight' />
        </div>
        <div class="item">
            <div class ='title'>09</div>
            <img :src="item" alt="" v-for = 'item in data.nine' />
        </div>

        <div class="item">
            <div class ='title'>10</div>
            <img :src="item" alt="" v-for = 'item in data.ten' />
        </div>

        <div class="item">
            <div class ='title'>11</div>
            <img :src="item" alt="" v-for = 'item in data.eleven' />
        </div>

        <div class="item">
            <div class ='title'>12</div>
            <img :src="item" alt="" v-for = 'item in data.twelve' />
        </div>
    </section>
</template>

<script>
import nonActive from '../../../assets/index/bar-none-active.png'
import active from '../../../assets/index/bar-active.png'
export default {
    data() {
        return {
        	data: {},
            list: []
        }
    },
    methods: {
    	init(data) {
    		const map = {} ;
    		Object.keys(data).forEach( item => {
    			let arr = [false , false ,false]
    			map[item] = arr.map( (i,idx) => {
    				console.log(Math.random() > 0.5);
    				return Math.random() > 0.5 ? active: nonActive//idx + 1 <= item ? active: nonActive
                })

            });
    		this.data = map ;
        }
    }
}
</script>

<style scoped>
.section{
    flex: 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: space-between;
    flex-wrap: wrap;
    padding: 5% 0 5% 0;
}
.item{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
    margin-bottom: 2%
}

.img-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
}
.item .title{
    color:#fff;
    margin-right: 5px;
}
</style>
