<template>
    <div class="container">
        <div class="header">
            <img src="../../assets/pic/titlea.png" class = 'title'>
        </div>
        <div class="expand-wrapper" :class="{'expand' : expand }">
            <div class="list-item-wrapper wrapper-bottom">
                <div v-for="item in list" class="s-item" @click="getData(item.id);expand=false">
                    {{ item.name}}
                </div>
            </div>
            <div class="close" @click="expand =false">关闭</div>
        </div>
        <div class="wrapper">
            <div class="left">
                <div class="slider" @click="expand = true ">展开</div>
                <div class="l-top">
                    <div class="sub">基本情况</div>
                    <div class="sub-title">{{ nameTitle }}</div>
                    <div class="sub-num">
                        <span>支部党员数量</span>
                        {{ memberCount }}人
                    </div>
                    <div class="sub-desc">
                        {{remark}}
                    </div>
                </div>
                <div class="l-middle">
                    <img :src="'http://kyzzb.bizzar.cn/head/' +head" alt="">

                    <div class="text-wrapper" style="display: flex;flex-direction: column;margin-left: 50px">
                        <div style="color:#fff;font-weight: bolder;font-size: 25px">{{ secretaryName }}</div>
                        <div style="display: flex;flex-direction: row;margin-top: 20px">
                            <div class="img-section" style="display: flex;flex-direction: row ">
                                <img style="display: block;width: 20px;height: 20px;margin-right: 10px" src="../../assets/pic/people.png" alt="">
                                <div style="color:#2ea7e0;font-size: 14px">{{ post }}</div>
                            </div>
                            <div style="display: flex;flex-direction: row">
                                <img style="display: block;width: 20px;height: 20px;margin-right: 10px" src="../../assets/pic/ttel.png" alt="">
                                <div style="color:#2ea7e0;font-size: 14px">
                                    {{ mobile }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="l-bottom">
                    <div class="sub" style="margin-bottom: 10px">支部成员列表</div>

                    <div class="list-wrapper wrapper-bottom">
                        <div>
                            <div class="list-item" v-for="item in memberList">
                                <img :src="'http://kyzzb.bizzar.cn/head/' + item.head" alt="">
                                <div style="flex:1">
                                    <div style="color: rgb(255, 255, 255);font-weight: bolder;font-size: 1.2rem;margin-bottom: 5px">
                                        {{ item.name }}
                                    </div>
                                    <div style="display: flex;flex-direction: row ">
                                        <img style="display: block;width: 15px;height: 15px;margin-right: 10px" src="../../assets/pic/people.png" alt="">
                                        <div style="color:#2ea7e0;font-size: 0.9rem">
                                            {{ item.post }}
                                        </div>
                                    </div>
                                    <div style="display: flex;flex-direction: row">
                                        <img style="display: block;width: 15px;height: 15px;margin-right: 10px" src="../../assets/pic/ttel.png" alt="">
                                        <div style="color:#2ea7e0;font-size: 0.9rem">
                                            {{ item.mobile }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">年龄结构</div>
                    <div class="age-wrapper">
                        <div class="age-title">
                            <div class="nan">男: {{ nan }}</div>
                            <div class="nv">女: {{ nv }}</div>
                        </div>

                        <div class="wrapper-item">
                            <div class="age-item" v-for="item in groups">
                                <div>{{ item.name }}</div>
                                <div class="item-count">
                                    <div class="item-percent a-left" :style="{width: nan ? item.manNum / nan * 100 +'%' : 0}"></div>
                                </div>
                                <div class="item-count">
                                    <div class="item-percent a-right" :style="{width: nv ? item.woManNum / nan * 100 +'%' : 0}"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">党员学历</div>
                    <div class = 'chart'>
                        <circleChart ref="xueli"></circleChart>
                    </div>
                </div>
                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">支持部泛化</div>
                    <div class = 'chart'>
                        <div class = 'bar-section-num' ref="zhuzhuangtu">
                        </div>
                    </div>
                </div>
                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">基础党委</div>
                    <div class = 'chart'>
                        <angle ref="dangWei"></angle>
                    </div>
                </div>
                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">三会一课</div>
                    <div class = 'sanhuiyike'>
                        <div v-for="item in metting">
                            <span>{{ item.name }}</span>
                            <div>
                                <img v-if="item.count < 25" src="../../assets/pic/bar-none-active.png" alt="">
                                <img v-if="item.count >= 25" src="../../assets/pic/bar-active.png" alt="">

                                <img v-if="item.count < 55" src="../../assets/pic/bar-none-active.png" alt="">
                                <img v-if="item.count >= 55" src="../../assets/pic/bar-active.png" alt="">

                                <img v-if="item.count < 75" src="../../assets/pic/bar-none-active.png" alt="">
                                <img v-if="item.count >= 75" src="../../assets/pic/bar-active.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">主题党日</div>
                    <div class = 'zhutidangri'>
                        <div v-for="item in themePartyDay">
                            <span>{{ item.name }}</span>

                            <img v-if="item.count < 25" src="../../assets/pic/human-yellow.png" alt="">
                            <img v-if="item.count >= 25" src="../../assets/pic/human.png" alt="">
                            <div :style="{color: item.count < 25 ? 'rgb(255, 241, 0)' : 'rgb(46, 167, 224)' }" class = 'count-title'>
                                {{ item.count }}%
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">党费缴纳</div>
                    <div class = 'fee' v-if="fee.length">
                        <div>
                            <span>{{ fee[0].name }}月</span>
                            <div>
                                {{ fee[0].count }}%
                            </div>
                        </div>
                        <div>
                            <span>{{ fee[6].name }}月</span>
                            <div>
                                {{ fee[6].count }}%
                            </div>
                        </div>

                        <div>
                            <span>{{ fee[1].name }}月</span>
                            <div>
                                {{ fee[1].count }}%
                            </div>
                        </div>
                        <div>
                            <span>{{ fee[7].name }}月</span>
                            <div>
                                {{ fee[7].count }}%
                            </div>
                        </div>

                        <div>
                            <span>{{ fee[2].name }}月</span>
                            <div>
                                {{ fee[2].count }}%
                            </div>
                        </div>
                        <div>
                            <span>{{ fee[8].name }}月</span>
                            <div>
                                {{ fee[8].count }}%
                            </div>
                        </div>

                        <div>
                            <span>{{ fee[3].name }}月</span>
                            <div>
                                {{ fee[3].count }}%
                            </div>
                        </div>
                        <div>
                            <span>{{ fee[9].name }}月</span>
                            <div>
                                {{ fee[9].count }}%
                            </div>
                        </div>

                        <div>
                            <span>{{ fee[4].name }}月</span>
                            <div>
                                {{ fee[4].count }}%
                            </div>
                        </div>
                        <div>
                            <span>{{ fee[10].name }}月</span>
                            <div>
                                {{ fee[10].count }}%
                            </div>
                        </div>

                        <div>
                            <span>{{ fee[5].name }}月</span>
                            <div>
                                {{ fee[5].count }}%
                            </div>
                        </div>
                        <div>
                            <span>{{ fee[11].name }}月</span>
                            <div>
                                {{ fee[11].count }}%
                            </div>
                        </div>
                    </div>
                </div>

                <div class="item-wrapper">
                    <div class="item-wrapper-title" style="margin-bottom: 10px">培训班</div>
                    <div class="chart">
                        <circleChart ref="peixunban"></circleChart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import lineChart from '../components/line' ;
import angle from '../components/pic/angle' ;
import bar from '../components/bar'
import barAge from '../components/bar-age'
import barHuman from '../components/bar-human'
import textNum from '../components/text-num'
import percentBar from '../components/percent-bar'
import sectionPercent from '../components/section-percent'
import middleBottom from '../components/middle-bottom'
import middleTop from '../components/middle-top'
import circleChart from '../components/pic/circle'
import * as echarts from "echarts";
import { service } from '../../service' ;

export default {
	components: { lineChart, angle, bar,
		percentBar, sectionPercent, barAge, barHuman ,textNum ,
		middleBottom, middleTop, circleChart
	},
	name: 'Home',
	data() {
		return {
			metting: null ,
			themePartyDay: [],
			fee: [],
			nameTitle: '' ,
			memberCount: '',
			secretaryName: '' ,
			post: '',
			mobile: '',
			memberList: [],
			remark: '',
			groups: [],
			nan: [],
			nv:[],
			head: '' ,
			expand: false,
			list: [],
            index: 1,
            timer: null
		}
	},
    destroyed() {
		clearInterval(this.timer)
    },
	mounted() {
		service.list().then( r => {
			this.list = r.data.data.list ;
			this.getData(1)
        })

        this.timer = setInterval( () => {
        	this.getData(this.list[this.index].id )
        } , 10000)
	},
	methods: {
		getData(id) {
			service.partyData({id})
				.then( r => {
					const data = r.data ;
					if( data.code !== '1') return ;
					this.remark = data.data.remark;
					this.nameTitle = data.data.name
					this.memberCount = data.data.memberNum
					this.secretaryName = data.data.secretaryName
					this.mobile = data.data.mobile
					this.post = data.data.post ;
					this.memberList = data.data.partyMembers
					this.head = data.data.head
					const arr = []
					const obj = data.data.partyMemberMetting
					Object.keys(data.data.partyMemberMetting).forEach((key , index) => {
						arr.push({
							name: index + 1 ,
							count: parseInt(obj[key])
						})
					});
					this.metting = arr ;

					const a = [] ;
					Object.keys(data.data.themePartyDay).forEach((key , index) => {
						a.push({
							name: index + 1 ,
							count: parseInt(obj[key])
						})
					});
					this.themePartyDay = a

					const b = [] ;
					Object.keys(data.data.partyFeePayment).forEach((key,index) => {
						b.push({
							name: index + 1 ,
							count: parseInt(obj[key])
						})
					})
					this.fee = b ;
					this.setAgeChart(data.data.ages)
					this.xueli(data.data.educations)
					this.setZhuZhuangTu(data.data)
					this.dangwei(data.data.partyBasePartyAffairsVM)
					this.peixunban( data.data.trainingClass )
				})
        },
		peixunban( data ) {
			this.$refs.peixunban.init(data.map( item => {
				return { value: item.result , name: item.name }
			}))
		},
		dangwei(data) {
			this.$refs.dangWei.init([data.partyClass,
				data.partyMemberMetting,
				data.themePartyDay,
				data.partyFeePayment,
				data.branchCommitteeMetting
			])
		},
		setAgeChart(data) {
			const groups = data.groups
			const names = groups.map( item => item.name )

			const nv = []
			const nan = []
			let n = 0 ;
			let v = 0 ;
			groups.forEach( item => {
				n += item.manNum / 1 ;
				v += item.woManNum / 1 ;
				nv.push( item.woManNum)
				nan.push( item.manNum)
			})

			this.groups = groups ;
			this.nan = n ;
			this.nv = v ;
		},
		xueli( data ) {
			this.$refs.xueli.init(data)
		},
		setZhuZhuangTu( data ) {
			const option = {
				xAxis: {
					type: 'category',
					data: ['基本组织', '基本队伍', '基本保障', '基本活动', '基本制度'],
					axisLabel: {
						textStyle: {
							color: '#fff',
							fontSize:'5',
						}
					},
					splitLine:{
						show:false
					},
				},
				yAxis: {
					splitLine:{
						show:false
					},
					textStyle: {
						color: '#fff',
						fontSize:'8',
					},
					type: 'value'
				},
				series: [{
					data: [data.baseOrgan, data.baseTeam, data.baseEnsure, data.baseActivitie, data.basicSystem],
					type: 'bar',
					showBackground: true,
					barWidth: '50%',
					itemStyle: {
						color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
							offset: 0,
							color: 'rgb(3, 178, 5)'
						}, {
							offset: 1,
							color: 'rgb(46, 167, 224)'
						}]),
						barBorderRadius:[10, 10, 10, 10]
					},
					barBorderRadius:[10, 10, 10, 10],
					backgroundStyle: {
						color: 'rgb(23, 42, 134)',
					}
				}]
			};

			const chart = this.$refs.zhuzhuangtu
			const e = echarts.init(chart)
			e.setOption(option)
		}
	}
}
</script>

<style scoped lang="scss">
@import "./style.scss";
</style>