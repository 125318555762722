<template>
    <section class="section">
        <div class = 'chart' ref="chart"></div>
    </section>
</template>

<script>
import * as echarts from "echarts";

export default {
    data() {
        return {}
    },
    methods: {
        init( data = [] ){
	        let total = 0 ;
	        const s = data.map( item => {
		        total += item.nums
		        return {value: item.nums, name: item.name}
            })
            const option ={
	            tooltip: {
		            trigger: 'item'
	            },
                color: ['#036eb8' , '#2ea7e0'] ,
	            series: [
		            {
			            type: 'pie',
			            radius: ['70%', '85%'],
			            center: ["52%", "50%"], 　　　　//这个属性调整图像的位置
			            avoidLabelOverlap: false,
			            itemStyle: {
				            borderRadius: 10,
                            borderWidth:10,	//边框的宽度
			            },
			            label: {
				            show: false,
				            position: 'center',
                            normal: {
                                show: true,
                                position: 'center',
                                color: 'rgb(47, 174, 234)',
	                            formatter: `{c|总人数}\n{b|${total}}人`,
	                            rich: {
		                            b: {                        //name 文字样式
			                            fontSize: 30,
			                            color: 'rgb(46, 167, 224)',
			                            align:"center",
		                            },
		                            c: {                   //value 文字样式
			                            fontSize: 10,
			                            lineHeight: 20,
			                            color: 'rgb(46, 167, 224)',
			                            align:"right",
			                            padding: [15, 15, 15, 15],
		                            }
	                            }
                            }
			            },
			            emphasis: {
				            label: {
					            show: false,
					            fontSize: '40',
					            fontWeight: 'bold'
				            }
			            },
			            labelLine: {
				            show: false
			            },
			            data: s
		            }
	            ]
            };
            const chart = this.$refs.chart
            const e = echarts.init(chart)
            e.setOption(option)
        }
    }
}
</script>

<style scoped>
.section{
    width: 390px;
    height: 200px;
    position: relative;
    top: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}
.chart{
    width: 100%;
    height: 100%;
}
</style>
