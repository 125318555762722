<template>
    <section class="section">
        <div class="container" :style='{ background: `url(${bg}) round`}'></div>
    </section>
</template>

<script>
import {service} from "../../../service";
import bgPng from '../../../assets/index/map.png'
export default {
	mounted() {
        service.getMap()
            .then( r => {
                if( r.data.code === '1' ) {
                	this.bg = r.data.msg ?  r.data.msg: bgPng ;
                } else {
                	this.bg = bgPng
                }
            })
	},
	data() {
        return {
        	bg: ''
        }
    }
}
</script>

<style scoped>
.section{
    width: 100%;
    flex: 1;
    position: relative;
    z-index: 99;
}
.container{
    width: 95%;
    height: 90%;
    margin: 0 auto;
}
</style>