<template>
    <section class="section">
        <div class = 'bar-section' v-for=" item in list">
            <div class = 'bar-title'>{{ item.month }}</div>
            <div class = 'bar-wrapper'>
                <div class="bar-wrapper-percent" :style="{width: total === 0 ? 0 : item.count / total * 100 + '%'}"></div>
            </div>
            <div class = 'title'>剩余:{{ item.count }}</div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            list: [] ,
            total: 0 ,
        }
    },
    methods: {
        init( data ) {
            this.list = data ;
            let t = 0 ;
            (data || []).forEach( item => {
                t += item.count
            })
            this.total = t ;
        }
    }
}
</script>

<style scoped>
.section{
    width: 100%;
    margin-top: 20px;
}
.bar-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem
}
.bar-title{
    margin-right: 20px;
    color:#fff;
    font-size: 10px;
}
.bar-wrapper{
    width: calc(100% - 10px);
    height: 5px;
    flex:1;
    margin-right: 10px;
    background: rgba(71, 110, 132, 0.67);
}
.bar-wrapper-percent{
    height: 100%;
    background: -webkit-gradient(linear,66% 35%, 0% 100%, from(#2da9df), to(#01BA03));
    border-radius: 10px;
}
.title{
    font-size: 12px;
    margin-right: 10px;
}
</style>
