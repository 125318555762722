import {API, CONFIG} from "./API";
import axios from 'axios' ;
var md5 = require('./crypto/md5-min.js') ;
import cryptoJs from 'crypto-js'
var Base64 = require('./crypto/base64.js') ;
export class ImageService {

    static uploadSingle( file ) {
        return new Promise( resolve => {
            const formData = ImageService.formData('map');
            formData.append('file' , file ) ;
            axios.post(API.uploadUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then( r => {
                resolve( API.uploadUrl +'/' + formData.get('key') )
            })
        });
    }

    static formData( fileName ){
        const formData = new FormData() ;
        const policyText = {
            "expiration": "2030-01-01T12:00:00.000Z", //设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
            "conditions": [
                ["content-length-range", 0, 1048576000] // 设置上传文件的大小限制
            ]
        };

        const policy = Base64.encode(JSON.stringify(policyText)) ;
        var bytes = cryptoJs.HmacSHA1(policy, CONFIG.OSS.accesskey, {asBytes: true});
        var signature = cryptoJs.enc.Base64.stringify( bytes )
        const time = +new Date() ;
        const key = md5.hexMD5( fileName + time ) ;

        formData.append('name' ,fileName)
        formData.append('key' ,key)
        formData.append('policy' ,policy)
        formData.append('OSSAccessKeyId' ,CONFIG.OSS.accessid)
        formData.append('success_action_status' , '200')
        formData.append('signature' ,signature)

        return formData ;
    }
}
