import axios from 'axios';

const GLOBAL = 'http://47.92.249.48:8088' ;

export const index = function(data){
    return axios.get(GLOBAL + "/api/Index/Get")
};

const group = function(data){
    return axios.get(GLOBAL + "/api/Index/Get")
};

const ana = function(data){
    return axios.get(GLOBAL + "/api/PartyAnalysis/Get")
};

const anaLine = function(data){
    return axios.get(GLOBAL + "/api/PartyGroupTeam/Get")
};

const basic = function(data){
    return axios.get(GLOBAL + "/api/BasicPartyAffairs/Get")
};

const urlUpload = function(data){
    return axios.post(GLOBAL + "/api/Index/SetMap"  , data )
};

const getMap = function(data){
    return axios.get(GLOBAL + "/api/Index/GetMap"  , data )
};

const warn = function(data){
    return axios.get(GLOBAL + "/api/Index/GetWarning"  , data )
};

const partyData = function(data){
    return axios.get(GLOBAL + "/api/Party/Get"  , { params: data } )
};

const partyGroupTeam = function( data ) {
    return axios.get(GLOBAL + '/api/PartyGroupTeam/Get' , data )
}

const member = function( data ) {
    return axios.get(GLOBAL + '/api/Member/Get' ,  { params: data } )
}

const zhibu = function( data ) {
    return axios.get(GLOBAL + '/api/PartyStandard/Get' ,  { params: data } )
}
const list = function(  ) {
    return axios.get(GLOBAL + '/api/Party/List?pageSize=1000&pageIndex=1' )
}
const mlist = function(  ) {
    return axios.get(GLOBAL + '/api/Member/List?pageSize=1000&pageIndex=1' )
}
export const service = {
    index , ana, anaLine, basic, urlUpload,
    getMap , warn, partyGroupTeam , partyData,
    member, zhibu , list ,mlist
};


export const convertObj = (target ,  obj ) => {
    const _o = {}
    Object.keys(target).forEach( key => {
        if( obj[key] ) _o[key] = obj[key]
    })
    return _o
}
