<template>
    <section class="section">
        <div ref = 'line' class = 'line'> </div>
    </section>
</template>

<script>
import * as echarts from 'echarts'
export default {
    data() {
        return {}
    },
    methods: {
        init( data ) {
            const option = {
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: (data.title || []).reverse(),
	                    axisLabel: {
		                    inside: false,
		                    textStyle: {
			                    color: '#fff',
			                    fontSize:'9',
			                    itemSize:''

		                    }
	                    },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        splitLine:{
                            show:false,
                            lineStyle:{
                                type:'dashed'
                            }
                        }
                    }
                ],
                series: [
                    {
                        type: 'line',
                        stack: '总量',
                        areaStyle: {},
                        smooth:true,
                        data: [100,100,100,100], //( data.value || [] ).reverse(),
	                    itemStyle : {
		                    color:{
			                    type: 'linear',
			                    x: 0,
			                    y: 0,
			                    x2: 0,
			                    y2: 1,
			                    colorStops: [{
				                    offset: 0, color: 'rgba(46,167,224,1)' // 0% 处的颜色
			                    }, {
				                    offset: 1, color: 'rgba(46 ,167, 224,0.000001)' // 100% 处的颜色
			                    }],
			                    global: false // 缺省为 false
		                    }
	                    },
                    }
                ]
            };
            const chart = this.$refs.line
            const e = echarts.init(chart)
            e.setOption(option)
        }
    }
}
</script>

<style scoped>
.section{
    width: 100%;
    height: 100%;
    position: relative;
}
.section .line {
    width: 100%;
    height: 100%;
}
</style>