<template>
    <div class="container" ref = 'container'>
        <div class="left-tool">
            <img @click="setType(1)" :src="f" alt="" :class = '{"activeImage": type === 1}'>
            <img @click="setType(2)" :src="s" alt="" :class = '{"activeImage": type === 2}'>
            <img @click="setType(3)" :src="t" alt="" :class = '{"activeImage": type === 3}'>
            <img @click="setType(4)" :src="ft" alt="" :class = '{"activeImage": type === 4}'>
            <img @click="setType(5)" :src="fi" alt="" :class = '{"activeImage": type === 5}'>
            <img class = 'sjx' @click="setType(6)" :src="si" alt="" :class = '{"activeImage": type === 6}'>

            <div class="upload" @click="upload">上传</div>
        </div>
        <div class="canvas-container">
            <canvas ref = 'canvas' @click="setImage"></canvas>
        </div>
        <div class="model" v-if="modalShow">
            <div>
                <input type="text" v-model = 'text' placeholder="请输入文字">
            </div>
            <div>
                <button @click="modalShow=false;">确定</button>
                <button @click="modalShow=false;text='';type = 0">关闭</button>
            </div>
        </div>

        <img :src="aaa" alt="">
    </div>
</template>

<script>
import paper from 'paper' ;
import map from '../assets/map.png'
import f from '../assets/map/1.png'
import s from '../assets/map/2.png'
import t from '../assets/map/3.png'
import ft from '../assets/map/4.png'
import fi from '../assets/map/5.png'
import si from '../assets/map/6.png'

import { ImageService } from '../service/image.service'

import {service } from '../service'
export default {
	data() {
		return {
			img:'', f, s, t, ft ,fi , si ,type: 0 ,
			modalShow: false , text: '' , aaa : '' ,
            mark: false
        }
	},
    components: {
    },
    mounted() {
		document.oncontextmenu = e => { return false ;e.preventDefault()}
    	this.init()
    },
    methods: {
    	init() {
    		const image = new Image() ;
    		image.src = map ;
    		image.onload = () => {
    			this.img = image ;
    			this.setUpCanvas()
            }
        },
	    setUpCanvas() {
    		const canvas = this.$refs.canvas ;
    		canvas.width = this.img.width ;
    		canvas.height = this.img.height;

    		paper.setup(canvas)

		    var raster = new paper.Raster({
			    image: this.img,
                size: new paper.Size({width: this.img.width , height: this.img.height}) ,
            });
		    raster.position = new paper.Point({ x: this.img.width / 2 , y: this.img.height /2})
        },
	    setType($event) {
    		this.type = $event === this.type ? 0 : $event
            if( this.type === 5 ) {
            	this.text = '' ;
            	this.modalShow = true
            }else {
            	this.text = ''
            }
        },
	    setImage(e) {
    		if( this.type === 0) return

            if( this.type === 5 ) {
	            const pointTextLocation = new paper.Point({x: e.offsetX , y: e.offsetY} );
	            const myText = new paper.PointText(pointTextLocation);
	            myText.fillColor = '#fff';
	            myText.fontSize = '11px'
	            myText.content = this.text;

                this.type = 0

	            myText.onClick = e => {
		            if( e.event.button === 2 )
			            myText.remove() ;
	            }

	            myText.onMouseDrag = e => {
		            myText.position = new paper.Point(e.point)
	            }

            	return ;
            }

            const image = new Image()
            const i = { 1: f , 2: s , 3:t , 4: ft , 6: si}
            image.src =  i[this.type]

            var raster = new paper.Raster({
                image: image,
            })

            raster.scale(0.7)
            raster.position = new paper.Point({ x: e.offsetX , y: e.offsetY})

            raster.onClick = e => {
                if( e.event.button === 2 )
                    raster.remove() ;
            }

            raster.onMouseDrag = e => {
            	raster.position = new paper.Point(e.point)
            }

		    this.type =  0;
        },
	    upload() {
    		if( this.mark ) return
    		const canvas = this.$refs.canvas ;
		    const dataurl = canvas.toDataURL("image/png");
		    var arr = dataurl.split(','),
			    mime = arr[0].match(/:(.*?);/)[1],
			    bstr = atob(arr[1]),
			    n = bstr.length,
			    u8arr = new Uint8Array(n);
		    while (n--) {
			    u8arr[n] = bstr.charCodeAt(n);
		    }
		    this.mark = true ;
		    const file = new File([u8arr], 'map', { type: mime });
		    alert('上传中....')
		    ImageService.uploadSingle(file)
            .then( r => {
            	service.urlUpload({ mapUrl: r })
                .then( r => {
                	if( r.data.code === '1') alert('成功') ;
	                paper.project.activeLayer.removeChildren()
	                this.setUpCanvas()
                }).finally( _ => this.mark = false)
            })
	    }
    },
}
</script>

<style scoped>
.container {
    width: 100%;
    height: 100%;
    background: url('../assets/bg.png') round ;
    position: relative;
}

.canvas-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.left-tool{
    width: 100%;
    height: 50px;
    padding: 5px 0;
    position: absolute;
    left: 0;
    bottom:0;
    background: rgba(62, 62, 62, 0.41);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.left-tool>img{
    display: block;
    height: 100%;
    width: auto;
    margin: 0 20px;
    cursor: pointer;
    transition: all .2s ease;
    z-index: 999;
}

.activeImage {
    border: 1px solid rgba(204, 204, 204 , 0.32);
    box-sizing: border-box;
    border-radius: 5px;
}

.model{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    z-index: 999;
    background: rgba(58, 58, 58 , 0.65);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.model input {
    width: 200px;
    height: 40px;
    background: rgba(0 ,0 ,0,0.49);
    color: #fff;
    border: 1px solid #717171;
    border-radius: 5px;
    text-indent: 20px;
}
.model button {
    width: 95px;
    height: 40px;
    border:none;
    text-align: center;
    margin: 10px 5px;
    cursor: pointer;
}
.sjx{
    width: 20px !important;
    height: 20px !important;
}
.upload{
    position: absolute;
    color: #fff;
    width: 120px;
    height: 40px;
    right: 53px;
    bottom: 11px;
    background: #196cd2;
    text-align: center;
    border-radius: 5px;
    line-height: 40px;
    font-size: 14px;
    cursor: pointer;
}
</style>