<template>
    <section class="section">
        <div class = 'bar-section' v-for = 'item in data'>
            <div class = 'bar-title'>{{ item.name }}</div>
            <div class = 'bar-wrapper'>
                <div class="bar-wrapper-percent" :style="{width: item.num / total * 100 + '%'}">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
        	data: [] ,
            total: 0
        }
    },
    methods: {
    	init( data ) {
		    this.data = data
            let total = 0
            this.data.forEach( item => {
            	total += item.num
            })

            this.total = total || 1
        }
    }
}
</script>

<style scoped>
.section{
    width: 100%;
    position: relative;
    top: 22%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
.bar-section{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}
.bar-title{
    margin-right: 20px;
    color:#fff;
    font-size: 10px;
    width: 40px;
}
.bar-wrapper{
    width: 100%;
    height: 5px;
    flex:1
}
.bar-wrapper-percent{
    height: 100%;
    background: -webkit-gradient(linear,66% 35%, 0% 100%, from(#2DA9DF), to(#01BA03));
    border-radius: 10px;
}
</style>