<template>
    <section class="section">
        <div class = 'chart' ref="chart"></div>
    </section>
</template>

<script>
import * as echarts from "echarts";

export default {
    data() {
        return {
        	index: 0,
        }
    },
    mounted() {
    },
	methods: {
        init( data = [] ){
            let total = 0 ;
            this.list = data ;
            const s = data.map( item => {
            	total += item.nums ? item.nums : 0;
            	return { name: item.name , value: item.nums}
            })
            const option ={
                tooltip: {
                    trigger: 'item'
                },
                color: ['#026eb6','#2ca4e1'] ,
                legend: {
                    top: 'bottom',
	                icon: "circle",
                    textStyle:{//图例文字的样式
                        color:'rgb(240, 248, 252)',
                        fontSize: 11
                    }
                },
                series: [
                    {
                        name: '访问来源',
                        type: 'pie',
                        radius: ['60%', '70%'],
                        center: ["50%", "35%"], 　　　　//这个属性调整图像的位置
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderWidth:10,	//边框的宽度
                        },
                        label: {
                            show: false,
                            position: 'center',
                            normal: {
                                show: true,
                                position: 'center',
                                color: 'rgb(47, 174, 234)',
                                formatter: `{c|总人数}\n{b|${total}}人`,
                                rich: {
                                    b: {                        //name 文字样式
                                        fontSize: 30,
                                        color: 'rgb(46, 167, 224)',
                                        align:"center",
                                    },
                                    c: {                   //value 文字样式
                                        fontSize: 10,
                                        lineHeight: 20,
                                        color: 'rgb(46, 167, 224)',
                                        align:"right",
                                        padding: [15, 15, 15, 15],
                                    }
                                }
                            }
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: s
                    }
                ]
            };
            const chart = this.$refs.chart
            const e = echarts.init(chart)
            e.setOption(option)
        }
    }
}
</script>

<style scoped>
.section{
    width: 100%;
    height: 90%;
    margin-top: 5%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}
.chart{
    width: 100%;
    height: 100%;
}
</style>