<template>
  <div class="container" style="position: relative">
    <div style="cursor: pointer;width: 15rem;height: 15rem;position: absolute;left: 22%;top: 35%;" @click="navigate"></div>
    <div style="cursor: pointer;width: 15rem;height: 15rem;position: absolute;left: 56%;top: 26%;" @click="navigate2"></div>
    <div style="cursor: pointer;width: 15rem;height: 15rem;position: absolute;left: 46%;top: 50%;" @click="navigate3"></div>
  </div>
</template>

<script>
import lineChart from '../components/line' ;
import angle from '../components/angle' ;
import bar from '../components/bar'
import barAge from '../components/bar-age'
import barHuman from '../components/bar-human'
import textNum from '../components/text-num'

import percentBar from '../components/percent-bar'
import sectionPercent from '../components/section-percent'
import middleBottom from '../components/middle-bottom'
import middleTop from '../components/middle-top'
import circleChart from '../components/circle'

export default {
  components: { lineChart, angle, bar,
    percentBar, sectionPercent, barAge, barHuman ,textNum ,
    middleBottom, middleTop, circleChart
  },
  name: 'Home',
  methods: {
    navigate() {
      this.$router.push('picAna')
    },
    navigate2() {
      this.$router.push('pic')
    },
    navigate3() {
      this.$router.push('group')
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: url('../../assets/watch/bg.png') round;
}
</style>
