<template>
    <div class="container">
        <div class="header">
            <img src="../../assets/ana-line/title.png" style="width: 12%" class = 'title'>
        </div>

        <div class="ana-l-container">
            <div class="ana-l-left">
                <div class="al-top">
                    <div class="left-header">
                        基本情况
                    </div>
                    <div class="left-container" >
                        <div class="c-left-header">
                            <div @click="changeType(1)" style="cursor: pointer" :class = '{"left-active":  activeType === 1 }'>支部班子</div>
                            <div @click="changeType(2)" style="cursor: pointer" :class = '{"left-active": activeType === 2 }'>党员</div>
                        </div>

                        <div v-if="activeType === 1">
                            <div class="data-count">
                                <div class="d-title">书记</div>
                                <div class="d-num">
                                    {{ secretary }}
                                </div>
                            </div>

                            <div class="data-count">
                                <div class="d-title">班子成员</div>
                                <div class="d-num">
                                    {{ teamMember }}
                                </div>
                            </div>
                        </div>

                        <div v-if="activeType === 2" class="partyMember">
                            {{ partyMember }}人
                        </div>
                    </div>
                </div>
                <div class="al-bottom">
                    <div class="left-header">
                        党龄分析
                    </div>
                    <div class="dangling">
                        <lineChart ref = 'lineChart' style="width: 100%;height: 80%"></lineChart>
                    </div>
                </div>
            </div>

            <div class = 'content-middle'>
                <div class="middle-top">
                    <div class="middle-title">
                        <div style="display: flex;flex-direction: row;justify-content: flex-start;align-items: center">
                            <div style="width: 120px;color:#fff;font-weight: bolder;font-size: 21px;margin-right: 50px">发展党员</div>
                        </div>
                    </div>
                    <div class="middle-line" ref="middleLine"></div>
                </div>
                <div class="middle-bottom">
                    <div class="bottom-wrapper">
                        <div class="m-left">
                            <div class="m-l-title">党员性质</div>
                            <div>
                                <circleChart ref="dangYuanChart"></circleChart>
                            </div>
                        </div>
                        <div class="m-right">
                            <div class="m-r-title">党员学历</div>
                            <div>
                                <circleChart ref="xueli"></circleChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ana-l-right">
                <div class="al-top">
                    <div class="right-header">
                        年龄结构
                    </div>

                    <div class="age-wrapper">
                        <div class="age-title">
<!--                            {{ nan }}-->
                            <div class="nan">男: </div>
<!--                            {{ nv }}-->
                            <div class="nv">女: </div>
                        </div>

                        <div class="wrapper-item">
                            <div class="age-item" v-for="item in groups">
                                <div>{{ item.name }}</div>
                                <div class="item-count">
<!--                                    :style="{width: nan ? item.manNum * 2 / nan * 100 +'%' : 0}"-->
                                    <div class="item-percent left" :style="{width: Math.random() * 100 + '%' }"></div>
                                </div>
                                <div class="item-count">
<!--                                    :style="{width: nv ? item.manNum * 2  / nan * 100 +'%' : 0}"-->
                                    <div class="item-percent right" :style="{width : Math.random() * 100  +'%' }"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="al-bottom" style="overflow: hidden">
                    <div class="right-header">
                        民族分布
                    </div>
                    <div class = 'wrapper-bottom'>
                        <div class = 'bar' v-for = 'item in nations'>
                            <div class="bar-title">{{ item.name }}</div>
                            <div class = 'percent-wrapper'>
                                <div class="bar-wrapper">
                                    <div class="percent" :style="{width: item.nums / nationTotal * 100 + '%'}"></div>
                                </div>
<!--                                <div>完成20%</div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import lineChart from '../components/line' ;
import angle from '../components/angle' ;
import bar from '../components/bar'
import barAge from '../components/bar-age'
import barHuman from '../components/bar-human'
import textNum from '../components/text-num'

import percentBar from '../components/percent-bar'
import sectionPercent from '../components/section-percent'
import middleBottom from '../components/middle-bottom'
import middleTop from '../components/middle-top'
import circleChart from '../components/circle'
import * as echarts from "echarts";
import {service, convertObj } from '../../service'
export default {
	components : {
		lineChart, angle, bar,
		percentBar, sectionPercent, barAge, barHuman ,textNum ,
		middleBottom, middleTop, circleChart
    } ,
	data(){
		return {
			secretary: 0 ,
			teamMember: 0 ,
			nations: [],
            nationTotal: 0 ,
			activeType: 1  ,
			timer: null ,
			partyMember: 0,
			groups: [] ,
			nan: [],
			nv: [],
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init(){
			service.anaLine()
				.then( ({data} ) => {
					if(data.code !== '1') return alert(data.msg) ;
					const res = data.data
					this.secretary = res.secretary
					this.teamMember = res.teamMember
					this.partyMember = res.partyMember

					this.lineChart(res.partyAges)
					this.setNations( res.nations )
					this.setAgeChart(res.ages)
					this.middleLine(res.memberNatureTen)
					this.xingzhi(res.natures)
					this.xueli(res.educations)
				})
		},
		xingzhi( data ) {
			this.$refs.dangYuanChart.init(data)
		},
		xueli( data ) {
			this.$refs.xueli.init(data)
		},
		setTime() {
			this.timer = setInterval( () => {
			  this.activeType = this.activeType === 1 ? 2 : 1
			} , 10000 )
		},
		setNations( data ) {
			this.nationTotal = 0 ;
			data.forEach( item => {
				this.nationTotal += item.nums
			})
			this.nations = data
		},
		changeType(type) {
			clearInterval( this.timer )
			this.activeType = type ;
			this.setTime()
		},
		lineChart( data ) {
			const title = data.map( item => item.name )
			const value = data.map( item => item.nums )
			this.$refs.lineChart.init({ title ,value})
		},
		middleLine( data ) {
			const names = data.map( item => item.name )
			const year = new Date().getFullYear() ;
			const years = []

			data.forEach( item => {
				years.push( {
					name: item.name,
					type: 'line',
					data: [
						item.one + 50 || 0,
						item.two + 50 || 0,
						item.three + 50 || 0,
						item.four + 50 || 0,
						item.five  + 50|| 0,
						item.sex  + 50|| 0,
						item.seven + 50 || 0,
						item.eight + 50 || 0,
						item.nine + 50 || 0,
						item.ten + 50 || 0,
					]
				})
			})

			const y = [] ;
			for( let i = 0 ; i< 10 ; i ++ ) y.push(year - i + '年' )
			const option = {
				legend: {
					data: names,
					left:'right',
					textStyle:{//图例文字的样式
						color:'rgb(240, 248, 252)',
					}
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: y.reverse(),
				},

				yAxis: {
					type: 'value',
					splitLine:{
						show:true,
						lineStyle:{
							color:'rgba(255,255,255,0.2)'
						}
					}
				},
				series: years
			};

			const chart = this.$refs.middleLine
			const e = echarts.init(chart)

			e.setOption(option)
		},
		setAgeChart(data) {
			const groups = data.groups
			const names = groups.map( item => item.name )

			const nv = []
			const nan = []
            let n = 0 ;
			let v = 0 ;
			groups.forEach( item => {
				n += item.manNum / 1 ;
				v += item.woManNum / 1 ;
				nv.push( item.woManNum)
				nan.push( item.manNum)
			})

            this.groups = groups ;
			this.nan = n ;
			this.nv = v ;
		}
	}
}
</script>

<style scoped lang="scss">
@import "./style.scss";

</style>